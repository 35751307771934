// Loading.js
import React from 'react';
// Styles.js
import styled from 'styled-components';

// svg 이미지
import loading from "../images/loading.svg";

export default () => {
	return (
		<Background>
			<LoadingImgWrap>
				<LoadingImg src={loading} />
			</LoadingImgWrap>
			<LoadingText>Loading</LoadingText>
		</Background>
	);
};

const Background = styled.div`
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background: #ffffffb7;
	z-index: 999;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 10px;
`
const LoadingText = styled.div`
	text-align: center;
	color: #b4ccfc;
`
const LoadingImgWrap = styled.div`
	display: flex;
`

const LoadingImg = styled.img`
	width: 80px;
`