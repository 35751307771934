import { Routes, Route, Link, useNavigate, Outlet } from 'react-router-dom';
import styled from 'styled-components';

import {useOverlay} from '../module/overlay/useOverlay'

import {RequestModal} from '../components/RequestModal'

function Footer() {
    const {open} = useOverlay()

    const handleClickRequest = function(){

        open(({ isOpen, close, exit }) => {
            return (
              <RequestModal
                title={"컨설팅 신청"}
                isOpen={isOpen}
                onClose={exit}
              />
            );
          });
    }

    return (
        <Wrap>
            <Container>
                <FlexWrap>
                    <CategoryWrap>
                        {/* <Category>
                            <CategoryTitle>기능 소개</CategoryTitle>
                            <CategoryItem>
                                <CategoryItemLink href="https://creator.ly/tutorial/proposal" target="_blank">제안 비교</CategoryItemLink>
                            </CategoryItem>
                            <CategoryItem>
                                <CategoryItemLink href="https://creator.ly/tutorial/proposal" target="_blank">캠페인 협의</CategoryItemLink>
                            </CategoryItem>
                            <CategoryItem>
                                <CategoryItemLink href="https://creator.ly/tutorial/contract" target="_blank">계약서 작성</CategoryItemLink>
                            </CategoryItem>
                            <CategoryItem>
                                <CategoryItemLink href="https://creator.ly/tutorial/escrow" target="_blank">안전 결제</CategoryItemLink>
                            </CategoryItem>
                            <CategoryItem>
                                <CategoryItemLink href="https://creator.ly/tutorial/analysis" target="_blank">분석 관리</CategoryItemLink>
                            </CategoryItem>
                        </Category> */}
                        <Category>
                            <CategoryTitle>가격 정책</CategoryTitle>
                            <CategoryItem>
                                <CategoryItemLink href="/priceGuide" target="_blank">요금제</CategoryItemLink>
                            </CategoryItem>
                        </Category>
                        <Category>
                            <CategoryTitle>회사소개</CategoryTitle>
                            <CategoryItem>
                                <CategoryItemLink href="https://creator.ly/team" target="_blank">팀 소개</CategoryItemLink>
                            </CategoryItem>
                            <CategoryItem>
                                <CategoryItemLink href="https://awakecorp.career.greetinghr.com/" target="_blank">채용</CategoryItemLink>
                            </CategoryItem>
                            <CategoryItem>
                                <CategoryItemLink href="https://partner.creator.ly/" target="_blank" onClick={function(e){e.preventDefault(); handleClickRequest()}}>문의하기</CategoryItemLink>
                            </CategoryItem>
                            <CategoryItem>
                                <CategoryItemLink href="https://creator.ly/policy" target="_blank">이용약관</CategoryItemLink>
                            </CategoryItem>
                            <CategoryItem>
                                <CategoryItemLink href="https://creator.ly/privacy" target="_blank">개인정보 처리방침</CategoryItemLink>
                            </CategoryItem>
                        </Category>
                        <Category>
                            <CategoryTitle>사이트맵</CategoryTitle>
                            <CategoryItem>
                                <CategoryItemLink href="/about" target="_blank">광고주를 위한 Ads</CategoryItemLink>
                            </CategoryItem>
                            <CategoryItem>
                                <CategoryItemLink href="https://meercat.io/intent" target="_blank">인스타그래머를 위한 분석리포트 “미어캣”</CategoryItemLink>
                            </CategoryItem>
                            <CategoryItem>
                                <CategoryItemLink href="https://creator.ly/" target="_blank">유튜버를 위한 광고주관리 “크리에이터리”</CategoryItemLink>
                            </CategoryItem>
                        </Category>
                    </CategoryWrap>
                    <InformationWrap>
                        <FooterLogo src={require("../images/foot-logo.png")}></FooterLogo>
                        <Information>
                            <InformationItem>
                                <InformationItemLabel>대표.</InformationItemLabel>
                                <InformationItemContent>김민준</InformationItemContent>
                            </InformationItem>
                            <InformationItem>
                                <InformationItemLabel>TEL.</InformationItemLabel>
                                <InformationItemContent>02-6953.0212</InformationItemContent>
                            </InformationItem>
                            <InformationItem>
                                <InformationItemLabel>E-Mail.</InformationItemLabel>
                                <InformationItemContent>sos@creator.ly</InformationItemContent>
                            </InformationItem>
                            <InformationItem>
                                <InformationItemLabel>HQ.</InformationItemLabel>
                                <InformationItemContent>서울시 마포구 마포대로 122, 프론트원 18층</InformationItemContent>
                            </InformationItem>
                            <InformationItem>
                                <InformationItemLabel>CX.</InformationItemLabel>
                                <InformationItemContent>서울시 강남구 언주로 537, 에이비티타워 11층</InformationItemContent>
                            </InformationItem>
                        </Information>
                    </InformationWrap>
                </FlexWrap>
                <FooterBottom>
                    <FooterBottomP>Copyright Ⓒ 2022 awakecorporation Inc. All right reserved</FooterBottomP>
                    <ThickFooterBottomP>통신판매업. 2020-서울강남-02980</ThickFooterBottomP>
                    <ThickFooterBottomP>사업자등록번호. 841-88-01897</ThickFooterBottomP>
                </FooterBottom>
            </Container>
        </Wrap>
    )
}

const Wrap = styled.div`
    background-color: #f9fafb;
`;

const Container = styled.div`
    width: 100%;
    max-width: 1050px;
    padding: 80px 16px;
    margin: 0 auto;
    @media screen and (max-width: 980px) {
        padding: 40px 16px;
    }
`;

const FlexWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 40px;
    margin-bottom: 72px;
`;

const CategoryWrap = styled.div`
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
    @media screen and (max-width: 980px) {
        gap: 24px;
        order: 2;
    }
`;
const Category = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
const CategoryTitle = styled.li`
    font-size: 14px; 
    opacity: 0.8; 
    color: #111; 
    font-weight: bold;;
`;
const CategoryItem = styled.li`
    
`;
const CategoryItemLink = styled.a`
    font-size: 14px;
    opacity: 0.8; 
    color: #555;
`



const InformationWrap = styled.div`
    @media screen and (max-width: 980px) {
        order: 1;
    }
`;
const FooterLogo = styled.img`
    width: 269px;
    margin-bottom: 30px;
`;
const Information = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;
const InformationItem = styled.li`
    display: flex;

`;
const InformationItemLabel = styled.h2`
    width: 60px;
    font-size: 14px;
    opacity: 0.8;
    color: #555;
`;
const InformationItemContent = styled.p`
    font-size: 14px;
    opacity: 0.8;
    color: #555;
`;
const FooterBottom = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 54px;
    @media screen and (max-width: 980px) {
        gap: 24px;
    }
`;
const FooterBottomP = styled.p`
    font-size: 12px;
    color: #ccc;
`;
const ThickFooterBottomP = styled(FooterBottomP)`
    opacity: 0.8;
    color: #555;
`;


export default Footer;