import styled from "styled-components";
import { MdClose } from "react-icons/md";
import { Input } from "./Input";
import { Label } from "./Label";
import { Flex } from "./Flex";
import { toast } from "react-toastify";
import axios from "axios";
import { useRef, useState } from "react";
import { PuffLoader } from "react-spinners";

export function RequestModal({ title = "", isOpen = false, onClose } = {}) {
  const containerRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [companyName, setCompanyName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [budget, setBudget] = useState();
  const [context, setContext] = useState();

  const handleClose = () => {
    containerRef.current?.classList.add("dismiss");
    containerRef.current?.addEventListener("animationend", () => {
      onClose?.();
    });
  };

  const trackGoogleAdsConversion = function () {
    window.gtag_report_conversion();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios
      .post(
        "https://ujo1wsqsxf.execute-api.ap-northeast-2.amazonaws.com/production/add-advertiser",
        {
          companyName,
          phoneNumber,
          budget,
          context,
          channel: e.target.channel.value,
        },
        {
          headers: {
            "x-api-key": "GE108R0nbu5EcgdAhV3md4yseV5z4hdv41qClrMy",
          },
        }
      )
      .then((response) => {
        toast.success(
          "🎉 성공적으로 요청되었습니다. 담당 매니저 배정 후 연락드리겠습니다.",
          { onOpen: () => handleClose() }
        );

        trackGoogleAdsConversion();
      })
      .catch((error) => {
        toast.error(
          "🤒 요청에 실패했습니다. 반복 실패할 경우 메일로 문의 부탁드립니다."
        );
      })
      .finally(() => setIsLoading(false));
  };

  if (isOpen === false) {
    return null;
  }

  return (
    <Wrap>
      <Container ref={containerRef}>
        <HeaderSection>
          <Title>{title}</Title>
          <CloseButton onClick={handleClose}>
            <MdClose size={32}></MdClose>
          </CloseButton>
        </HeaderSection>
        <form onSubmit={handleSubmit}>
          <ContentSection>
            <FormItem>
              <Label>업체명</Label>
              <Flex.Row>
                <Input
                  disabled={isLoading}
                  full
                  required
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </Flex.Row>
            </FormItem>
            <FormItem>
              <Label>전화번호</Label>
              <Flex.Row>
                <Input
                  disabled={isLoading}
                  full
                  required
                  type="tel"
                  placeholder="01000000000"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Flex.Row>
            </FormItem>
            <FormItem>
              <Label>예산범위 (만원)</Label>
              <Flex.Row>
                <Input
                  disabled={isLoading}
                  min={0}
                  type={"number"}
                  required
                  placeholder={500}
                  value={budget}
                  onChange={(e) => setBudget(e.target.value)}
                />
                <span>만원</span>
              </Flex.Row>
            </FormItem>
            <FormItem>
              <Label>채널</Label>
              <Flex.Row style={{ gap: 4 }}>
                <Input
                  name="channel"
                  defaultChecked
                  id="instagram"
                  value="instagram"
                  disabled={isLoading}
                  min={0}
                  type={"radio"}
                  required
                  placeholder={500}
                />
                <label htmlFor="instagram">인스타그램</label>
                <Input
                  name="channel"
                  disabled={isLoading}
                  min={0}
                  type={"radio"}
                  required
                  placeholder={500}
                  id="youtube"
                  value="youtube"
                />
                <label htmlFor="instagram">유튜브</label>
                <Input
                  name="channel"
                  disabled={isLoading}
                  min={0}
                  type={"radio"}
                  required
                  placeholder={500}
                  id="naver"
                  value="naver"
                />
                <label htmlFor="instagram">네이버 블로그</label>
              </Flex.Row>
            </FormItem>
            <FormItem>
              <Label>문의내용</Label>
              <Flex.Row>
                <Input
                  disabled={isLoading}
                  full
                  type="text"
                  value={context}
                  onChange={(e) => setContext(e.target.value)}
                  required
                  minLength={10}
                />
              </Flex.Row>
            </FormItem>
            <FormItem>
              <Flex.Row>
                <Input
                  id="agree"
                  disabled={isLoading}
                  type="checkbox"
                  required
                  minLength={10}
                />
                <Label
                  htmlFor="agree"
                  style={{ fontSize: 14, fontWeight: 500, marginLeft: 8 }}
                >
                  개인정보 수집 및 이용 및 마케팅 활용 전체 동의
                </Label>
              </Flex.Row>
            </FormItem>
          </ContentSection>
          <ButtonSection>
            <ConfirmButton disabled={isLoading}>
              {isLoading ? <PuffLoader size={24} color={"white"} /> : "확인"}
            </ConfirmButton>
          </ButtonSection>
        </form>
      </Container>
    </Wrap>
  );
}

const Wrap = styled.footer`
  z-index: 1002;
  position: fixed;

  top: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.section`
  padding: 24px;
  max-width: 480px;
  width: 100%;

  box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.2);
  margin: 0 24px;
  background: white;

  border-radius: 8px;
  overflow-y: scroll;

  animation: 0.12s show forwards ease-out;

  &.dismiss {
    animation: 0.12s erase forwards ease-out;
  }

  @keyframes show {
    from {
      transform: scale(0.96);
      opacity: 0.2;
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes erase {
    from {
      opacity: 1;
      transform: scale(1);
    }

    to {
      transform: scale(0.96);
      opacity: 0;
    }
  }
`;

const HeaderSection = styled.header`
  top: 0;
  left: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Title = styled.h2`
  font-size: 28px;
  font-weight: 700;
`;

const ButtonSection = styled.section`
  display: flex;
  flex-direction: row;

  width: 100%;
`;

const Button = styled.button`
  height: 40px;
  padding: 0 16px;
  border-radius: 8px;
`;

const CloseButton = styled(Button)`
  padding: 0;
  height: 40px;
  width: 40px;
  :active {
    opacity: 0.6;
  }
`;

const ContentSection = styled.section`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
`;

const ConfirmButton = styled(Button)`
  flex: 1;
  background: rgb(68, 128, 247);
  color: white;
  font-weight: bold;
  font-size: 16px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  :not(:disabled) {
    :active {
      opacity: 0.6;
    }

    :hover:not(:active) {
      background: #296ff9;
    }
  }

  :disabled {
    cursor: not-allowed;

    background: lightgray;
  }
`;
