import { Routes, Route, Link, useNavigate, Outlet } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";

//components
import { RequestModal } from "./RequestModal";

// 이미지
import creatorly_logo from "../images/creatorly-logo2.png";
import menu_w from "../images/Untitled/menu-w.png";
import menu_b from "../images/Untitled/menu-b.png";
import close from "../images/Untitled/close.png";

import { useOverlay } from "../module/overlay/useOverlay";

function Header(mainHeader) {
  const { open } = useOverlay();
  let navigate = useNavigate();

  const openRequestModal = function (e) {
    e.preventDefault();
    open(({ isOpen, exit }) => {
      return (
        <RequestModal title={"컨설팅 신청"} isOpen={isOpen} onClose={exit} />
      );
    });
  };

  // 스크롤시 해드 변함
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollEvent, setScrollEvent] = useState(false);
  const updateScroll = () => {
    setScrollPosition(window.scrollY || document.documentElement.scrollTop);
  };
  useEffect(() => {
    window.addEventListener("scroll", updateScroll);
    scrollPosition < 100 ? setScrollEvent(false) : setScrollEvent(true);
  });

  // 모바일 해드 열고 닫기
  let [mbMenu, setMbMenu] = useState(false);

  return (
    <>
      <Wrap mainHeader={mainHeader} scrollEvent={scrollEvent}>
        <Container>
          <PcHeader>
            <Logo to={"/"}></Logo>
            <Navigation>
              <Item
                mainHeader={mainHeader}
                scrollEvent={scrollEvent}
                onClick={() => {
                  navigate("/about");
                }}
              >
                서비스 소개
              </Item>
              <Item
                mainHeader={mainHeader}
                scrollEvent={scrollEvent}
                onClick={() => {
                  navigate("/business");
                }}
              >
                비즈니스
              </Item>
              <Item
                mainHeader={mainHeader}
                scrollEvent={scrollEvent}
                onClick={() => {
                  navigate("/priceGuide");
                }}
              >
                가격 안내
              </Item>
            </Navigation>
            <HeaderRi>
              <ButtonLink
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  open(({ isOpen, exit }) => {
                    return (
                      <RequestModal
                        title={"컨설팅 신청"}
                        isOpen={isOpen}
                        onClose={exit}
                      />
                    );
                  });
                }}
              >
                문의하기
              </ButtonLink>
            </HeaderRi>
          </PcHeader>
          <MbHeader mbMenu={mbMenu}>
            <MbLogo
              onClick={() => {
                navigate("/");
              }}
            ></MbLogo>
            <ButtonWrap>
              <SmallButtonLink
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  open(({ isOpen, close, exit }) => {
                    return (
                      <RequestModal
                        title={"컨설팅 신청"}
                        isOpen={isOpen}
                        onClose={exit}
                      />
                    );
                  });
                }}
              >
                문의하기
              </SmallButtonLink>
              {mainHeader.mainHeader ? (
                !scrollEvent ? (
                  <MbMenuBtn
                    btnImg={menu_w}
                    mbMenu={mbMenu}
                    onClick={() => {
                      setMbMenu(!mbMenu);
                    }}
                  ></MbMenuBtn>
                ) : (
                  <MbMenuBtn
                    btnImg={menu_b}
                    mbMenu={mbMenu}
                    onClick={() => {
                      setMbMenu(!mbMenu);
                    }}
                  ></MbMenuBtn>
                )
              ) : (
                <MbMenuBtn
                  btnImg={menu_b}
                  mbMenu={mbMenu}
                  onClick={() => {
                    setMbMenu(!mbMenu);
                  }}
                ></MbMenuBtn>
              )}

              {/* <MbMenuBtn mainHeader={mainHeader} mbMenuBtn={mbMenuBtn} onClick={()=>{setMbMenuBtn(!mbMenuBtn)}}></MbMenuBtn> */}
            </ButtonWrap>
            {mbMenu ? (
              <MbMenuWrap>
                <MbMenu>
                  <MbMenuItem
                    onClick={() => {
                      navigate("/about");
                    }}
                  >
                    서비스 소개
                  </MbMenuItem>
                  <MbMenuItem
                    onClick={() => {
                      navigate("/business");
                    }}
                  >
                    비즈니스
                  </MbMenuItem>
                  <MbMenuItem
                    onClick={() => {
                      navigate("/priceGuide");
                    }}
                  >
                    가격 안내
                  </MbMenuItem>
                </MbMenu>
              </MbMenuWrap>
            ) : (
              ""
            )}
          </MbHeader>
        </Container>
      </Wrap>
      <Space></Space>
    </>
  );
}

// pc =========================================================================
const Wrap = styled.div`
  width: 100vw;
  position: fixed;
  height: 80px;
  top: 0;
  left: 0;
  z-index: 99;
  transition: background-color 0.3s;
  background-color: ${function (props) {
    if (props.mainHeader.mainHeader) {
      if (!props.scrollEvent) {
        return "#06101d";
      } else {
        return "#fff";
      }
    } else {
      return "#fff";
    }
  }};
  @media screen and (max-width: 800px) {
    height: 70px;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 1620px;
  padding: 0 16px;
  margin: 0 auto;
`;

const PcHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const Logo = styled(Link)`
  display: block;
  width: 140px;
  height: 80px;
  background-image: url(${creatorly_logo});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;

const Navigation = styled.ul`
  display: flex;
  gap: 60px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const Item = styled.li`
  font-weight: bold;
  transition: all 0.3s;
  cursor: pointer;
  color: ${function (props) {
    if (props.mainHeader.mainHeader) {
      if (!props.scrollEvent) {
        return "#fff";
      } else {
        return "#06101d";
      }
    } else {
      return "#06101d";
    }
  }};
  @media screen and (max-width: 1100px) {
    font-size: 12px;
  }
`;

const HeaderRi = styled.div`
  display: flex;
  gap: 24px;
`;

const Introduction = styled.a`
  display: flex;
  gap: 10px;
  align-items: center;
  color: ${function (props) {
    if (props.mainHeader.mainHeader) {
      if (!props.scrollEvent) {
        return "#fff";
      } else {
        return "#06101d";
      }
    } else {
      return "#06101d";
    }
  }};
  @media screen and (max-width: 1100px) {
    font-size: 12px;
  }
`;
const IntroductionImg = styled.img`
  width: 18px;
  @media screen and (max-width: 1100px) {
    width: 14px;
  }
`;

const ButtonLink = styled.a`
  display: flex;
  width: 126px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #4480f7;
  font-weight: bold;
  color: #fff;
  transition: all 0.3s;
  &:hover {
    background-color: #296ff9;
  }
  @media screen and (max-width: 1100px) {
    width: 58px;
    height: 24px;
    font-size: 12px;
  }
`;
// 모바일 =========================================================================
const MbHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100vw;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  background-color: ${(props) => (props.mbMenu ? "#fff" : "transparent")};
  @media screen and (max-width: 800px) {
    display: flex;
  }
`;
const MbLogo = styled.button`
  display: block;
  width: 120px;
  height: 70px;
  background-image: url(${creatorly_logo});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;
const ButtonWrap = styled.div`
  display: flex;
  gap: 16px;
`;
const SmallButtonLink = styled(ButtonLink)`
  width: 58px;
  height: 24px;
  font-size: 12px;
`;
const MbMenuBtn = styled.button`
  width: 24px;
  height: 24px;
  background-image: url(${(props) => (props.mbMenu ? close : props.btnImg)});
  background-size: cover;
`;
const MbMenuWrap = styled.div`
  position: fixed;
  left: 0;
  top: 70px;
  width: 100%;
  height: calc(100vh - 70px);
`;
const MbMenu = styled.ul`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
  padding: 60px 20px 0;
  gap: 30px;
`;
const MbMenuItem = styled.li`
  width: 100%;
  height: 40px;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;
`;
const MbMenuItemLink = styled.a``;

const Space = styled.div`
  min-height: 80px;
  @media screen and (max-width: 800px) {
    min-height: 70px;
  }
`;

export default Header;
