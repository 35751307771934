import { Routes, Route, Link, useNavigate, Outlet } from "react-router-dom";
import { useEffect, useState } from "react";

import styled, { keyframes } from "styled-components";

import Header from "../components/Header";
import Footer from "../components/Footer";

import Loading from "./Loading";

// 페이드인
import AOS from "aos";
import "aos/dist/aos.css";
// 체크 이미지
import CheckImg from "../images/price_01/Vector 128.png";
import { useOverlay } from "../module/overlay/useOverlay";
import { FixedFooter } from "../components/FixedFooter";
import { RequestModal } from "../components/RequestModal";

const Price = {
  Standard: "78만원",
  Plus: "174만원",
  Premium: "351만원",
  Custom: "상담 후 견적",
};

function PriceGuide() {
  // 해시태그 부스트 = 0 , 솔루션 패키지 = 1
  const [product, setProduct] = useState(1);

  // 보여지는 페키지
  const [nowpackage, setNowPackage] = useState(2);

  const {open} = useOverlay()

  const openRequestModal = function (e) {
    e.preventDefault();
    open(({ isOpen, exit }) => {
      return (
        <RequestModal title={"컨설팅 신청"} isOpen={isOpen} onClose={exit} />
      );
    });
  };

  return (
    <>
      {/* header */}
      <Header mainHeader={false}></Header>
      <Banner>
        <BannerLetterWrap>
          <Container data-aos="zoom-in">
            <BannerBigLetter>가격 안내</BannerBigLetter>
            <BannerSmallLetter>목적에 따라 플랜을 설정하세요</BannerSmallLetter>
            <EventBanner>
              {/* <EventBannerTextWrap>re("../images/price_01/Group 19843.png")} />
                            <MbEventBannerImg src={req
                                <EventBannerText>지금 가입시</EventBannerText>
                                <EventBannerBlueText>100만원 상당 이용료 지급</EventBannerBlueText>
                            </EventBannerTextWrap>
                            <EventBannerImg src={requiuire("../images/price_01/Group 19838.png")} /> */}
              <EventBannerImg
                src={require("../images/price_01/banner-event-pc.png")}
              />
              <MbEventBannerImg
                src={require("../images/price_01/banner-event-mb.png")}
              />
            </EventBanner>
          </Container>
        </BannerLetterWrap>

        <BannerBackground>
          <AnimationItemIcon
            left={"24%"}
            delay={"-5s"}
            mbdisplay
            mbLeft={"20%"}
          >
            <AnimationItemIconImg
              src={require("../images/About_PC/Group 19790.png")}
            ></AnimationItemIconImg>
          </AnimationItemIcon>

          <AnimationItem
            left={"38%"}
            delay={"-5.5s"}
            opacity={"0.6"}
            mbdisplay
            mbLeft={"40%"}
          ></AnimationItem>

          <AnimationItem
            left={"70%"}
            delay={"-7s"}
            mbdisplay
            mbLeft={"63%"}
          ></AnimationItem>

          <AnimationItemIcon
            left={"77%"}
            delay={"-6s"}
            width={"83px"}
            mbdisplay
            mbLeft={"70%"}
          >
            <AnimationItemIconImg
              src={require("../images/About_PC/Group 19789.png")}
            ></AnimationItemIconImg>
          </AnimationItemIcon>
        </BannerBackground>
      </Banner>
      <PriceSection>
        <ProductButtonWrap>
          {/* <ProductButton
            now={product}
            onClick={() => {
              setProduct(0);
            }}
          >
            <ProductButtonImg
              src={require("../images/price_01/Group 19844.png")}
            />
            해시태그 부스트
          </ProductButton> */}
          <ProductButton
            now={product}
            onClick={() => {
              setProduct(1);
            }}
          >
            <ProductButtonImg
              src={require("../images/price_01/Group 19845.png")}
            />
            솔루션 패키지
          </ProductButton>
        </ProductButtonWrap>

        {product == 0 ? (
          // 패스트 체험단 모집
          <Container>
            <PriceSectionBlueTitle>인플루언서 마케팅 v2</PriceSectionBlueTitle>
            <PriceSectionTitle>해시태그 부스트</PriceSectionTitle>
            <PriceSectionSmallTitle>
              &nbsp;
            </PriceSectionSmallTitle>
            <InquiryWrap>
              <InquiryItemUl>
                <InquiryItemli>
                  <InquiryLabel>베이직 부스트</InquiryLabel>
                  <InquiryInformationWrap>
                    <InquiryTextWrap1>
                      <InquiryCheckText>
                        인플루언서 총 32명 진행
                      </InquiryCheckText>
                    </InquiryTextWrap1>
                    <BoostInquiryTextWrap2>
                      <InquiryCheckText margin={"5px"}>
                        내용
                      </InquiryCheckText>
                      <InquiryText>피드 1회 + 스토리 1회</InquiryText>
                    </BoostInquiryTextWrap2>
                    <BoostInquiryTextWrap3>
                      <InquiryCheckText margin={"5px"}>
                        인플루언서
                      </InquiryCheckText>
                      <InquiryText>팔로워 1천명 이상 (20명)</InquiryText>
                      <InquiryText>팔로워 8천명 이상 (12명)</InquiryText>
                    </BoostInquiryTextWrap3>
                    <BoostInquiryTextWrap4>
                      <InquiryCheckText margin={"5px"}>
                        제공 항목
                      </InquiryCheckText>
                      <InquiryText>해시태그 추천</InquiryText>
                      <InquiryText>브랜드 FIT 분석</InquiryText>
                      <InquiryText>영향력 분석</InquiryText>
                    </BoostInquiryTextWrap4>
                    <BoostInquiryTextWrap5>
                      <InquiryCheckText margin={"5px"}>
                        피드 유지기간
                      </InquiryCheckText>
                      <InquiryText>평균 6개월 (보장 1개월)</InquiryText>
                    </BoostInquiryTextWrap5>
                    <InquiryButtonWrap>
                      {/* <InquiryPrice>{Price.Standard}</InquiryPrice>
                      <InquiryPeriod>(할부가능)</InquiryPeriod> */}
                      <InquiryButton href="https://partner.creator.ly/" onClick={openRequestModal}>
                        문의하기
                      </InquiryButton>
                    </InquiryButtonWrap>
                  </InquiryInformationWrap>
                </InquiryItemli>
                <InquiryItemli>
                  <InquiryLabel>스탠다드 부스트</InquiryLabel>
                  <InquiryInformationWrap>
                    <InquiryTextWrap1>
                      <InquiryCheckText>
                        인플루언서 총 24명 진행
                      </InquiryCheckText>
                    </InquiryTextWrap1>
                    <BoostInquiryTextWrap2>
                      <InquiryCheckText margin={"5px"}>
                      내용
                      </InquiryCheckText>
                      <InquiryText>피드 1회 + 스토리 1회</InquiryText>
                    </BoostInquiryTextWrap2>
                    <BoostInquiryTextWrap3>
                      <InquiryCheckText margin={"5px"}>
                        인플루언서
                      </InquiryCheckText>
                      <InquiryText>팔로워 1천명 이상 (15명)</InquiryText>
                      <InquiryText>팔로워 8천명 이상 (5명)</InquiryText>
                      <InquiryText>팔로워 3만명 이상 (3명)</InquiryText>
                      <InquiryText>팔로워 8만명 이상 (1명)</InquiryText>
                    </BoostInquiryTextWrap3>
                    <BoostInquiryTextWrap4>
                      <InquiryCheckText margin={"5px"}>
                        제공 항목
                      </InquiryCheckText>
                      <InquiryText>베이직 제공 항목 포함</InquiryText>
                      <InquiryText>콘텐츠 플래닝</InquiryText>
                      <InquiryText>해시태그 분석 및 업데이트</InquiryText>
                    </BoostInquiryTextWrap4>
                    <BoostInquiryTextWrap5>
                      <InquiryCheckText margin={"5px"}>
                        피드 유지기간
                      </InquiryCheckText>
                      <InquiryText>평균 6개월 (보장 1개월)</InquiryText>
                    </BoostInquiryTextWrap5>
                    <InquiryButtonWrap>
                      {/* <InquiryPrice>{Price.Plus}</InquiryPrice>
                      <InquiryPeriod>(할부가능)</InquiryPeriod> */}
                      <InquiryButton href="https://partner.creator.ly/" onClick={openRequestModal}>
                        문의하기
                      </InquiryButton>
                    </InquiryButtonWrap>
                  </InquiryInformationWrap>
                </InquiryItemli>
                <InquiryItemli>
                  <InquiryLabel>프리미엄 부스트</InquiryLabel>
                  <InquiryInformationWrap>
                    <InquiryTextWrap1>
                      <InquiryCheckText>
                      인플루언서 총 12명 진행
                      </InquiryCheckText>
                    </InquiryTextWrap1>
                    <BoostInquiryTextWrap2>
                      <InquiryCheckText margin={"5px"}>
                      내용
                      </InquiryCheckText>
                      <InquiryText>피드 1회 + 스토리 1회</InquiryText>
                    </BoostInquiryTextWrap2>
                    <BoostInquiryTextWrap3>
                      <InquiryCheckText margin={"5px"}>
                        인플루언서
                      </InquiryCheckText>
                      <InquiryText>팔로워 8천명 이상 (10명)</InquiryText>
                      <InquiryText>팔로워 3만명 이상 (1명)</InquiryText>
                      <InquiryText>팔로워 15만명 이상 (1명)</InquiryText>
                    </BoostInquiryTextWrap3>
                    <BoostInquiryTextWrap4>
                      <InquiryCheckText margin={"5px"}>
                        제공 항목
                      </InquiryCheckText>
                      <InquiryText>스탠다드 제공 항목 포함</InquiryText>
                      <InquiryText>상위 노출을 위한 성과 측정</InquiryText>
                      <InquiryText>맞춤형 마케팅 전략 개발</InquiryText>
                    </BoostInquiryTextWrap4>
                    <BoostInquiryTextWrap5>
                      <InquiryCheckText margin={"5px"}>
                        피드 유지기간
                      </InquiryCheckText>
                      <InquiryText>평균 6개월 (보장 1개월)</InquiryText>
                    </BoostInquiryTextWrap5>
                    <InquiryButtonWrap>
                      {/* <InquiryPrice>{Price.Premium}</InquiryPrice>
                      <InquiryPeriod>(할부가능)</InquiryPeriod> */}
                      <InquiryButton href="https://partner.creator.ly/" onClick={openRequestModal}>
                        문의하기
                      </InquiryButton>
                    </InquiryButtonWrap>
                  </InquiryInformationWrap>
                </InquiryItemli>
                <InquiryItemli color="solid 1px #4480f7">
                  <InquiryLabel color={"#4480f7"}>첫 계약 패키지</InquiryLabel>
                  <InquiryInformationWrap>
                    <InquiryTextWrap1>
                      <InquiryCheckText>
                        첫 상담 후 14일 이내 계약시
                      </InquiryCheckText>
                    </InquiryTextWrap1>
                    <BoostInquiryTextWrap2>
                      <InquiryCheckText margin={"5px"}>
                      내용
                      </InquiryCheckText>
                      <InquiryText>피드 1회 + 스토리 1회</InquiryText>
                    </BoostInquiryTextWrap2>
                    <BoostInquiryTextWrap3>
                      <InquiryCheckText margin={"5px"}>
                        인플루언서
                      </InquiryCheckText>
                      <InquiryText>팔로워 1천명 이상 (20명)</InquiryText>
                      <InquiryText>팔로워 8천명 이상 (12명)</InquiryText>
                    </BoostInquiryTextWrap3>
                    <BoostInquiryTextWrap4>
                      <InquiryCheckText margin={"5px"}>
                        제공 항목
                      </InquiryCheckText>
                      <InquiryText>베이직 제공 항목과 동일</InquiryText>
                    </BoostInquiryTextWrap4>
                    <BoostInquiryTextWrap5>
                      <InquiryCheckText margin={"5px"}>
                        피드 유지기간
                      </InquiryCheckText>
                      <InquiryText>평균 6개월 (보장 1개월)</InquiryText>
                    </BoostInquiryTextWrap5>
                    <InquiryButtonWrap>
                      {/* <InquiryPrice>{Price.Custom}</InquiryPrice>
                      <InquiryPeriod>(할부가능)</InquiryPeriod> */}
                      <InquiryButton
                        href="https://partner.creator.ly/"
                        color={"#4480f7"}
                        onClick={openRequestModal}
                      >
                        문의하기
                      </InquiryButton>
                    </InquiryButtonWrap>
                  </InquiryInformationWrap>
                </InquiryItemli>
              </InquiryItemUl>
            </InquiryWrap>
          </Container>
        ) : (
          // 솔루션 패키지
          <Container>
            <PriceSectionTitle>솔루션 패키지</PriceSectionTitle>
            <PriceSectionSmallTitle>
              캠페인 유형에 따라 원하는 <MbBreak /> 플랜을 선택해주세요{" "}
            </PriceSectionSmallTitle>

            {/* 문의하기 */}
            <InquiryWrap>
              <InquiryItemUl>
                <InquiryItemli>
                  <InquiryLabel>스탠다드</InquiryLabel>
                  <InquiryInformationWrap>
                    <InquiryTextWrap1>
                      <InquiryCheckText>
                        인플루언서 마케팅이 처음이에요
                      </InquiryCheckText>
                    </InquiryTextWrap1>
                    <InquiryTextWrap2>
                      <InquiryCheckText margin={"5px"}>
                        인플루언서 총 28명 진행
                      </InquiryCheckText>
                      <InquiryText>팔로워 500명 이상 (15명)</InquiryText>
                      <InquiryText>팔로워 1천명 이상 (10명)</InquiryText>
                      <InquiryText>팔로워 1만명 이상 (3명)</InquiryText>
                    </InquiryTextWrap2>
                    <InquiryTextWrap3>
                      <InquiryCheckText margin={"5px"}>
                        인플루언서
                      </InquiryCheckText>
                      <InquiryText>6개월 이상 (보장 1개월)</InquiryText>
                    </InquiryTextWrap3>
                    <DetailView href="#PackageTable">자세히 보기</DetailView>
                    <InquiryButtonWrap>
                      {/* <InquiryPrice>{Price.Standard}</InquiryPrice>
                      <InquiryPeriod>(할부가능)</InquiryPeriod> */}
                      <InquiryButton href="https://partner.creator.ly/" onClick={openRequestModal}>
                        문의하기
                      </InquiryButton>
                    </InquiryButtonWrap>
                  </InquiryInformationWrap>
                </InquiryItemli>
                <InquiryItemli>
                  <InquiryLabel>스탠다드 플러스</InquiryLabel>
                  <InquiryInformationWrap>
                    <InquiryTextWrap1>
                      <InquiryCheckText>
                        브랜드 잠재소비자를 공략하고 싶어요
                      </InquiryCheckText>
                    </InquiryTextWrap1>
                    <InquiryTextWrap2>
                      <InquiryCheckText margin={"5px"}>
                        인플루언서 총 34명 진행
                      </InquiryCheckText>
                      <InquiryText>팔로워 500명 이상 (15명)</InquiryText>
                      <InquiryText>팔로워 1천명 이상 (15명)</InquiryText>
                      <InquiryText>팔로워 1만명 이상 (3명)</InquiryText>
                      <InquiryText>팔로워 8만명 이상 (1명)</InquiryText>
                    </InquiryTextWrap2>
                    <InquiryTextWrap3>
                      <InquiryCheckText margin={"5px"}>
                        피드 유지 기간
                      </InquiryCheckText>
                      <InquiryText>6개월 이상 (보장 1개월)</InquiryText>
                    </InquiryTextWrap3>
                    <DetailView href="#PackageTable">자세히 보기</DetailView>
                    <InquiryButtonWrap>
                      {/* <InquiryPrice>{Price.Plus}</InquiryPrice>
                      <InquiryPeriod>(할부가능)</InquiryPeriod> */}
                      <InquiryButton href="https://partner.creator.ly/" onClick={openRequestModal}>
                        문의하기
                      </InquiryButton>
                    </InquiryButtonWrap>
                  </InquiryInformationWrap>
                </InquiryItemli>
                <InquiryItemli>
                  <InquiryLabel>프리미엄</InquiryLabel>
                  <InquiryInformationWrap>
                    <InquiryTextWrap1>
                      <InquiryCheckText>
                        브랜드 인지도를 높이고 싶어요
                      </InquiryCheckText>
                    </InquiryTextWrap1>
                    <InquiryTextWrap2>
                      <InquiryCheckText margin={"5px"}>
                        인플루언서 총 46명 진행
                      </InquiryCheckText>
                      <InquiryText>팔로워 500명 이상 (20명)</InquiryText>
                      <InquiryText>팔로워 1천명 이상 (17명)</InquiryText>
                      <InquiryText>팔로워 1만명 이상 (7명)</InquiryText>
                      <InquiryText>팔로워 8만명 이상 (1명)</InquiryText>
                      <InquiryText>팔로워 15만명 이상 (1명)</InquiryText>
                    </InquiryTextWrap2>
                    <InquiryTextWrap3>
                      <InquiryCheckText margin={"5px"}>
                        피드 유지 기간
                      </InquiryCheckText>
                      <InquiryText>6개월 이상 (보장 1개월)</InquiryText>
                    </InquiryTextWrap3>
                    <DetailView href="#PackageTable">자세히 보기</DetailView>
                    <InquiryButtonWrap>
                      {/* <InquiryPrice>{Price.Premium}</InquiryPrice>
                      <InquiryPeriod>(할부가능)</InquiryPeriod> */}
                      <InquiryButton href="https://partner.creator.ly/" onClick={openRequestModal}>
                        문의하기
                      </InquiryButton>
                    </InquiryButtonWrap>
                  </InquiryInformationWrap>
                </InquiryItemli>
                <InquiryItemli color="solid 1px #4480f7">
                  <InquiryLabel color={"#4480f7"}>커스터마이징</InquiryLabel>
                  <InquiryInformationWrap>
                    <InquiryTextWrap1>
                      <InquiryCheckText>
                        공격적인 바이럴 마케팅이 필요해요
                      </InquiryCheckText>
                    </InquiryTextWrap1>
                    <InquiryTextWrap2>
                      <InquiryCheckText margin={"5px"}>
                        커스터마이징 가능
                      </InquiryCheckText>
                    </InquiryTextWrap2>
                    <InquiryTextWrap3></InquiryTextWrap3>
                    <DetailView href="#PackageTable">자세히 보기</DetailView>
                    <InquiryButtonWrap>
                      {/* <InquiryPrice>{Price.Custom}</InquiryPrice>
                      <InquiryPeriod>(할부가능)</InquiryPeriod> */}
                      <InquiryButton
                        href="https://partner.creator.ly/"
                        color={"#4480f7"}
                        onClick={openRequestModal}
                      >
                        문의하기
                      </InquiryButton>
                    </InquiryButtonWrap>
                  </InquiryInformationWrap>
                </InquiryItemli>
              </InquiryItemUl>
            </InquiryWrap>

            {/* FAQ */}
            <FAQ>
              <FAQWrap>
                <Question>고급형은 일반형과 무슨 차이인가요?</Question>
                <Answer>
                  특정 카테고리의 인플루언서 모집을 원하는 경우입니다. 팔로워
                  1천명 이상 부터는 인플루언서 무드나 카테고리를 요청할 수
                  있습니다.
                </Answer>
                <Example>
                  예) 운동하는 20대 여성 모집 / 패션을 좋아하는 힙한 20대
                  인플루언서 모집 / 3040대 직장인 인플루언서 모집
                </Example>
              </FAQWrap>
              <FAQWrap>
                <Question>‘제품 미협찬'은 어떤 경우에 해당하나요?</Question>
                <Answer>
                  서비스 리뷰, 이벤트 바이럴 혹은 방문 리뷰의 캠페인과 같이 제품
                  협찬이 아닌 경우입니다. 이 경우 인플루언서의 섭외비용이
                  높아집니다.
                </Answer>
              </FAQWrap>
            </FAQ>

            {/* 페키지 가격표 */}
            <PackageTable id="PackageTable">
              <PackageThead>
                <PackageTh left={"flex-start"}>패키지</PackageTh>
                <PackageTh>스탠다드</PackageTh>
                <PackageTh>스탠다드 플러스</PackageTh>
                <PackageTh>프리미엄</PackageTh>
                <PackageTh borderRight={"unset"}>커스터마이징</PackageTh>
              </PackageThead>
              {/* PackageThead 모바일 */}
              <MbPackageThead>
                <MbPackageTheadTitle>패키지</MbPackageTheadTitle>
                <MbPackageTheadWrap>
                  <MbPackageTheadUl>
                    <MbPackageTheadLi
                      now={nowpackage}
                      onClick={() => {
                        setNowPackage(2);
                      }}
                    >
                      스탠다드
                    </MbPackageTheadLi>
                    <MbPackageTheadLi
                      now={nowpackage}
                      onClick={() => {
                        setNowPackage(3);
                      }}
                    >
                      스탠다드 플러스
                    </MbPackageTheadLi>
                    <MbPackageTheadLi
                      now={nowpackage}
                      onClick={() => {
                        setNowPackage(4);
                      }}
                    >
                      프리미엄
                    </MbPackageTheadLi>
                    <MbPackageTheadLi
                      now={nowpackage}
                      onClick={() => {
                        setNowPackage(5);
                      }}
                    >
                      커스터마이징
                    </MbPackageTheadLi>
                  </MbPackageTheadUl>
                </MbPackageTheadWrap>
              </MbPackageThead>

              <PackageTbody>
                <PackageTr>
                  <PackageTbLabel>추천해요</PackageTbLabel>
                  <PackageTb now={nowpackage}>
                    <PackageTbText>
                      인플루언서 마케팅이 <br /> 처음이에요
                    </PackageTbText>
                  </PackageTb>
                  <PackageTb now={nowpackage}>
                    <PackageTbText>
                      브랜드 잠재소비자를 <br /> 공략하고 싶어요
                    </PackageTbText>
                  </PackageTb>
                  <PackageTb now={nowpackage}>
                    <PackageTbText>
                      브랜드 인지도를 <br /> 높이고 싶어요
                    </PackageTbText>
                  </PackageTb>
                  <PackageTb now={nowpackage}>
                    <PackageTbText>
                      공격적인 바이럴 <br /> 마케팅이 필요해요
                    </PackageTbText>
                  </PackageTb>
                </PackageTr>

                {/* <PackageTr>
                  <PackageTbLabel>가격</PackageTbLabel>
                  <PackageTb now={nowpackage}>
                    <PackageTbBoldText>
                      {Price.Standard}
                      <PackageTbPeriodText>(할부가능)</PackageTbPeriodText>
                    </PackageTbBoldText>
                  </PackageTb>
                  <PackageTb now={nowpackage}>
                    <PackageTbBoldText>
                      {Price.Plus}
                      <PackageTbPeriodText>(할부가능)</PackageTbPeriodText>
                    </PackageTbBoldText>
                  </PackageTb>
                  <PackageTb now={nowpackage}>
                    <PackageTbBoldText>
                      {Price.Premium}
                      <PackageTbPeriodText>(할부가능)</PackageTbPeriodText>
                    </PackageTbBoldText>
                  </PackageTb>
                  <PackageTb now={nowpackage}>
                    <PackageTbBoldText>
                      {Price.Custom}
                      <PackageTbPeriodText>(할부가능)</PackageTbPeriodText>
                    </PackageTbBoldText>
                  </PackageTb>
                </PackageTr> */}

                <PackageTr>
                  <PackageTbLabel>인플루언서</PackageTbLabel>
                  <PackageTb now={nowpackage}>
                    <PackageTbBoldText>총 28명 진행</PackageTbBoldText>
                    <PackageTbSmallText>팔로워 500명 이상 (15명)</PackageTbSmallText>
                    <PackageTbSmallText>팔로워 1천명 이상 (10명)</PackageTbSmallText>
                    <PackageTbSmallText>팔로워 1만명 이상 (3명)</PackageTbSmallText>
                  </PackageTb>
                  <PackageTb now={nowpackage}>
                    <PackageTbBoldText>총 34명 진행</PackageTbBoldText>
                    <PackageTbSmallText>팔로워 500명 이상 (15명)</PackageTbSmallText>
                    <PackageTbSmallText>팔로워 1천명 이상 (15명)</PackageTbSmallText>
                    <PackageTbSmallText>팔로워 1만명 이상 (3명)</PackageTbSmallText>
                    <PackageTbSmallText>팔로워 8만명 이상 (1명)</PackageTbSmallText>
                  </PackageTb>
                  <PackageTb now={nowpackage}>
                    <PackageTbBoldText>총 46명 진행</PackageTbBoldText>
                    <PackageTbSmallText>팔로워 500명 이상 (20명)</PackageTbSmallText>
                    <PackageTbSmallText>팔로워 1천명 이상 (17명)</PackageTbSmallText>
                    <PackageTbSmallText>팔로워 1만명 이상 (7명)</PackageTbSmallText>
                    <PackageTbSmallText>팔로워 8만명 이상 (1명)</PackageTbSmallText>
                    <PackageTbSmallText>팔로워 15만명 이상 (1명)</PackageTbSmallText>
                  </PackageTb>
                  <PackageTbCenter now={nowpackage}>
                    <PackageTbSmallText>커스터마이징 가능</PackageTbSmallText>
                  </PackageTbCenter>
                </PackageTr>

                {/* <PackageTr>
                  <PackageTbLabel>
                    인플루언서 인당 광고비
                    <AddExplanation>
                      (상품 가격 내에 포함되어 있음)
                    </AddExplanation>
                  </PackageTbLabel>
                  <PackageTb now={nowpackage}>
                    <PackageTbSmallText>6천원 (F500~)</PackageTbSmallText>
                    <PackageTbSmallText>1만원 (F1천 ~)</PackageTbSmallText>
                    <PackageTbSmallText>15만원 (F1만~)</PackageTbSmallText>
                  </PackageTb>
                  <PackageTb now={nowpackage}>
                    <PackageTbSmallText>6천원 (F500~)</PackageTbSmallText>
                    <PackageTbSmallText>1만원 (F1천 ~)</PackageTbSmallText>
                    <PackageTbSmallText>15만원 (F1만~)</PackageTbSmallText>
                    <PackageTbSmallText>40만원 (F8만~)</PackageTbSmallText>
                  </PackageTb>
                  <PackageTb now={nowpackage}>
                    <PackageTbSmallText>6천원 (F500~)</PackageTbSmallText>
                    <PackageTbSmallText>1만원 (F1천 ~)</PackageTbSmallText>
                    <PackageTbSmallText>15만원 (F1만~)</PackageTbSmallText>
                    <PackageTbSmallText>40만원 (F8만~)</PackageTbSmallText>
                  </PackageTb>
                  <PackageTbCenter now={nowpackage}>
                    <PackageTbSmallText>변동 가능</PackageTbSmallText>
                  </PackageTbCenter>
                </PackageTr> */}

                <PackageTr>
                  <PackageTbLabel>2차 라이선스</PackageTbLabel>
                  <PackageTbCenter now={nowpackage}>
                    <BlueCheck
                      src={require("../images/price_01/BlueCheck.png")}
                    />
                  </PackageTbCenter>
                  <PackageTb now={nowpackage}>
                    <PackageTbSmallText>
                      팔로워 80,000명 이상부터 추가비용 발생
                    </PackageTbSmallText>
                    <PackageTbSmallText fontSize={"12px"}>
                      (인플루언서가 비용 제시)
                    </PackageTbSmallText>
                  </PackageTb>
                  <PackageTb now={nowpackage}>
                    <PackageTbSmallText>
                      팔로워 80,000명 이상부터 추가비용 발생
                    </PackageTbSmallText>
                    <PackageTbSmallText fontSize={"12px"}>
                      (인플루언서가 비용 제시)
                    </PackageTbSmallText>
                  </PackageTb>
                  <PackageTbCenter now={nowpackage}>
                    <PackageTbSmallText>변동 가능</PackageTbSmallText>
                  </PackageTbCenter>
                </PackageTr>

                <PackageTr>
                  <PackageTbLabel>피드 유지기간</PackageTbLabel>
                  <PackageTb now={nowpackage}>
                    <PackageTbSmallText>6개월 이상</PackageTbSmallText>
                    <PackageTbSmallText fontSize={"12px"}>
                      (보장 1개월)
                    </PackageTbSmallText>
                  </PackageTb>
                  <PackageTb now={nowpackage}>
                    <PackageTbSmallText>6개월 이상</PackageTbSmallText>
                    <PackageTbSmallText fontSize={"12px"}>
                      (보장 1개월)
                    </PackageTbSmallText>
                  </PackageTb>
                  <PackageTb now={nowpackage}>
                    <PackageTbSmallText>6개월 이상</PackageTbSmallText>
                    <PackageTbSmallText fontSize={"12px"}>
                      (보장 1개월)
                    </PackageTbSmallText>
                  </PackageTb>
                  <PackageTb now={nowpackage}>
                    <PackageTbSmallText>6개월 이상</PackageTbSmallText>
                    <PackageTbSmallText fontSize={"12px"}>
                      (보장 1개월)
                    </PackageTbSmallText>
                  </PackageTb>
                </PackageTr>

                {/* <PackageTr>
                  <PackageTbLabel>성과분석 리포트 (PDF)</PackageTbLabel>
                  <PackageTbCenter now={nowpackage}>
                    <BlueCheck src={require("../images/price_01/X.png")} />
                  </PackageTbCenter>
                  <PackageTbCenter now={nowpackage}>
                    <BlueCheck src={require("../images/price_01/X.png")} />
                  </PackageTbCenter>
                  <PackageTbCenter now={nowpackage}>
                    <BlueCheck src={require("../images/price_01/X.png")} />
                  </PackageTbCenter>
                  <PackageTbCenter now={nowpackage}>
                    <BlueCheck
                      src={require("../images/price_01/BlueCheck.png")}
                    />
                  </PackageTbCenter>
                </PackageTr> */}

                <PackageTr>
                  <PackageTbLabel>엑셀 분석리포트<br /><span style={{color: '#CBC7C8', fontSize: 14, fontWeight: 500}}>(매크로 급간 이상 제공)</span></PackageTbLabel>
                  <PackageTbCenter now={nowpackage}>
                    <BlueCheck src={require("../images/price_01/X.png")} />
                  </PackageTbCenter>
                  <PackageTbCenter now={nowpackage}>
                    <BlueCheck
                      src={require("../images/price_01/BlueCheck.png")}
                    />
                  </PackageTbCenter>
                  <PackageTbCenter now={nowpackage}>
                    <BlueCheck
                      src={require("../images/price_01/BlueCheck.png")}
                    />
                  </PackageTbCenter>
                  <PackageTbCenter now={nowpackage}>
                    <BlueCheck
                      src={require("../images/price_01/BlueCheck.png")}
                    />
                  </PackageTbCenter>
                </PackageTr>

                <PackageTr>
                  <PackageTbLabel>담당 매니저 배정</PackageTbLabel>
                  <PackageTbCenter now={nowpackage}>
                    <BlueCheck
                      src={require("../images/price_01/BlueCheck.png")}
                    />
                  </PackageTbCenter>
                  <PackageTbCenter now={nowpackage}>
                    <BlueCheck
                      src={require("../images/price_01/BlueCheck.png")}
                    />
                  </PackageTbCenter>
                  <PackageTbCenter now={nowpackage}>
                    <BlueCheck
                      src={require("../images/price_01/BlueCheck.png")}
                    />
                  </PackageTbCenter>
                  <PackageTbCenter now={nowpackage}>
                    <BlueCheck
                      src={require("../images/price_01/BlueCheck.png")}
                    />
                  </PackageTbCenter>
                </PackageTr>
              </PackageTbody>
            </PackageTable>
          </Container>
        )}
      </PriceSection>
      <Footer />
    </>
  );
}

// 컨테이너
const Container = styled.div`
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
  @media screen and (max-width: 1090px) {
    padding: 0 16px;
  }
`;
// 타이틀
const Title = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: #a3b0bb;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 20px;
  @media screen and (max-width: 880px) {
    font-size: 18px;
    margin-bottom: 15px;
  }
`;
// 타이틀 아이콘
const Icon = styled.img`
  display: inline-block;
  max-width: 28px;
  @media screen and (max-width: 880px) {
    max-width: 20px;
  }
`;
// 타이틀 아래 글씨
const Additional = styled.p`
  font-size: 46px;
  line-height: 1.5;
  font-weight: 800;
  color: #222222;
  text-align: center;
  margin-bottom: ${(props) => props.margin_bottom || "0px"};
  @media screen and (max-width: 880px) {
    font-size: 24px;
  }
`;
const SmallAdditional = styled.p`
  font-size: 20px;
  opacity: 0.6;
  color: #000;
  font-size: 20px;
  margin-top: 15px;
  text-align: center;
  line-height: 1.5;
  @media screen and (max-width: 880px) {
    font-size: 16px;
  }
`;
const Break = styled.br`
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
const MbBreak = styled.br`
  display: none;
  @media screen and (max-width: 800px) {
    display: unset;
  }
`;

// section1=========================================================================
const Banner = styled.div`
  position: relative;
  height: auto;
  background-color: #fff;
  padding: 150px 0 217px;
  @media screen and (max-width: 800px) {
    padding: 80px 0 146px;
  }
`;
const BannerLetterWrap = styled.div`
  position: relative;
  text-align: center;
  z-index: 1;
`;
const BannerBigLetter = styled.p`
  font-size: 70px;
  font-weight: 800;
  color: #111;
  margin-bottom: 10px;
  @media screen and (max-width: 800px) {
    font-size: 28px;
  }
`;
const BannerSmallLetter = styled.p`
  font-size: 30px;
  line-height: 1.3;
  color: #bfc8dc;
  font-weight: bold;
  @media screen and (max-width: 800px) {
    font-size: 20px;
  }
`;
const EventBanner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 900px;
  border-radius: 20px;
  margin: 60px auto 0;
  position: relative;
  @media screen and (max-width: 680px) {
    max-width: 320px;
  }
`;
const EventBannerTextWrap = styled.div`
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: left;
  position: relative;
  z-index: 1;
  @media screen and (max-width: 680px) {
    padding-left: 15px;
  }
`;
const EventBannerText = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
  color: #fff;
  @media screen and (max-width: 680px) {
    font-size: 14px;
  }
`;
const EventBannerBlueText = styled.p`
  font-size: 30px;
  font-weight: bold;
  line-height: 1.3;
  color: #8db3ff;
  @media screen and (max-width: 680px) {
    font-size: 16px;
  }
`;
const EventBannerImg = styled.img`
  width: 100%;
  @media screen and (max-width: 680px) {
    display: none;
  }
`;
const MbEventBannerImg = styled.img`
  display: none;
  width: 100%;
  right: 0;
  @media screen and (max-width: 680px) {
    display: block;
  }
`;
const BannerBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
`;
const rotate = keyframes` /* 2. css코드를 씀. */
    0%{
        top: 100%;
    }
    100%{
        top: -100px;
    }
`;
const AnimationItem = styled.div`
  position: absolute;
  width: 27px;
  height: 27px;
  border-radius: 100%;
  background-color: #4480f7;
  top: 100%;
  left: ${(props) => props.left || "0px"};
  opacity: ${(props) => props.opacity || "1"};
  animation: ${rotate};
  animation-duration: 6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: running;
  animation-delay: ${(props) => props.delay || "0s"};
  @media screen and (max-width: 800px) {
    display: ${(props) => props.mbdisplay || "none"};
    left: ${(props) => props.mbLeft || "0px"};
    width: 14px;
    height: 14px;
  }
`;
const AnimationItemIcon = styled(AnimationItem)`
  width: ${(props) => props.width || "61px"};
  height: auto;
  background-color: unset;
  @media screen and (max-width: 800px) {
    width: 36px;
  }
`;
const AnimationItemIconImg = styled.img`
  width: 100%;
`;

// section2=========================================================================
const PriceSection = styled.div`
  padding: 100px 0 150px;
  background-color: #f6f8fa;
  position: relative;
  @media screen and (max-width: 800px) {
    padding: 80px 0;
  }
`;

// 패스트 체험단 모집,크리에이터리 패키지 버튼
const ProductButtonWrap = styled.ul`
  position: absolute;
  bottom: 100%;
  display: flex;
  // gap: 30px;
  left: calc((100vw - 1050px) / 2);
  @media screen and (max-width: 1082px) {
    left: 16px;
  }
  @media screen and (max-width: 800px) {
    left: 50%;
    transform: translateX(-50%);
  }
`;
const ProductButton = styled.li`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: max-content;
  height: 72px;
  padding: 12px 16px;
  border-radius: 15px 15px 0 0;
  font-size: 22px;
  color: #8a9095;
  background-color: #fff;
  cursor: pointer;
  // &:nth-child(${(props) => props.now + 1}) {
  //   background-color: #f6f8fa;
  //   font-weight: bold;
  //   color: #4480f7;
  // }
  &:nth-child(${(props) => props.now}) {
    background-color: #f6f8fa;
    font-weight: bold;
    color: #4480f7;
  }
  @media screen and (max-width: 800px) {
    width: 149px;
    height: 49px;
    font-size: 16px;
  }
`;
const ProductButtonImg = styled.img`
  max-width: 58px;
  position: absolute;
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  @media screen and (max-width: 800px) {
    max-width: 53px;
  }
`;

// 패스트 체험단 모집
const FastExperienceWrap = styled.div`
  display: flex;
  width: 100%;
  max-width: 848px;
  gap: 40px;
  margin: 60px auto 0;
  @media screen and (max-width: 800px) {
    gap: 20px;
    margin: 30px auto 0;
  }
  @media screen and (max-width: 570px) {
    flex-direction: column;
  }
`;
const FastExperienceItem = styled.div`
  position: relative;
  width: calc((100% - 40px) / 2);
  padding: 30px 20px 108px;
  border-radius: 20px;
  box-shadow: 0 0 30px 0 rgba(246, 248, 250, 0.6);
  border: solid 1px #f6f6f6;
  background-color: #fff;
  @media screen and (max-width: 800px) {
    width: calc((100% - 20px) / 2);
    padding: 20px 15px 88px;
  }
  @media screen and (max-width: 570px) {
    width: 100%;
  }
`;
const FastExperienceItemImg = styled.img`
  max-width: 40px;
  height: 40px;
  background-color: aliceblue;
  margin-bottom: 15px;
  @media screen and (max-width: 800px) {
    max-width: 30px;
    height: 30px;
    margin-bottom: 10px;
  }
`;
const FastExperienceItemTitleWrap = styled.div`
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 30px;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 40px);
    height: 1px;
    background-color: #f6f8fa;
  }
`;
const FastExperienceItemTitle = styled.p`
  font-size: 26px;
  font-weight: 800;
  line-height: 1.5;
  color: #111;
  margin-bottom: 8px;
  @media screen and (max-width: 800px) {
    font-size: 22px;
  }
`;
const FastExperienceItemExplanation = styled.p`
  line-height: 1.5;
`;
const FastExperienceItemContentWrap = styled.div`
  margin-bottom: 30px;
`;
const FastExperienceItemButtonWrap = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 20px;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 800px) {
    padding: 20px 15px;
  }
`;
const FastExperienceItemButtonTitle = styled.p`
  font-size: 26px;
  font-weight: bold;
  color: #111;
  @media screen and (max-width: 800px) {
    font-size: 20px;
  }
`;
const FastExperienceItemButtonPeriod = styled.span`
  font-size: 16px;
  font-weight: normal;
  color: #aaa;
  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`;
const FastExperienceItemButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 116px;
  height: 48px;
  border-radius: 10px;
  background-color: ${(props) => props.color || "#4480f7"};
  color: #fff;
`;

// 크리에이터리 패키지
const PriceSectionBlueTitle = styled.p`
  font-size: 30px;
  line-height: 1.3;
  font-weight: bold;
  color: #bfc8dc;
  margin-bottom: 8px;
  text-align: center;
  @media screen and (max-width: 800px) {
    font-size: 20px;
  }
`;
const PriceSectionTitle = styled(PriceSectionBlueTitle)`
  font-size: 46px;
  font-weight: 800;
  color: #000;
  margin-bottom: 15px;
  @media screen and (max-width: 800px) {
    font-size: 26px;
  }
`;
const PriceSectionSmallTitle = styled(PriceSectionBlueTitle)`
  margin-bottom: 16px;  
  font-size: 16px;
  color: #555;
  font-weight: normal;
  line-height: normal;
  @media screen and (max-width: 800px) {
    line-height: 1.5;
  }
`;
// 협찬 버튼
const SponsorshipBtnWrap = styled.div`
  display: flex;
  padding: 8px;
  border-radius: 20px;
  border: solid 1px #fff;
  background-color: #f6f8fa;
  width: 100%;
  max-width: 456px;
  margin: 40px auto 48px;
  @media screen and (max-width: 800px) {
    max-width: 282px;
    margin: 45px auto;
    gap: 5px;
  }
`;
const SponsorshipBtn = styled.button`
  width: calc((100% - 8px) / 2);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  font-size: 22px;
  color: #8a9095;
  border-radius: 15px;
  @media screen and (max-width: 800px) {
    width: calc((100% - 5px) / 2);
    height: 37px;
    font-size: 14px;
  }
`;
const SponsorshipBtnActive = styled(SponsorshipBtn)`
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  font-weight: bold;
  color: #4480f7;
`;
// 일반형 고급형 선택
const TypeSwitchWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-bottom: 30px;
  @media screen and (max-width: 800px) {
    margin-bottom: 20px;
  }
`;
const TypeName = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: #bfc8dc;
  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`;
const TypeNameActive = styled(TypeName)`
  color: #4480f7;
`;
const TypeSwitch = styled.button`
  display: flex;
  width: 40px;
  height: 20px;
  border-radius: 10px;
  background-color: #4480f7;
  position: relative;
  @media screen and (max-width: 800px) {
    font-size: 14px;
    width: 32px;
    height: 16px;
    border-radius: 8px;
  }
`;
const TypeSwitchButton = styled.div`
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 100%;
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  @media screen and (max-width: 800px) {
    width: 10px;
    height: 10px;
    left: 3px;
  }
`;
const TypeSwitchButtonActive = styled(TypeSwitchButton)`
  left: unset;
  right: 4px;
  @media screen and (max-width: 800px) {
    right: 3px;
  }
`;
// 문의하기
const InquiryWrap = styled.div`
  width: 100%;
  overflow: auto;
  margin-bottom: 30px;
`;
const InquiryItemUl = styled.ul`
  width: 1050px;
  display: flex;
  gap: 16px;
`;
const InquiryItemli = styled.li`
  width: calc((100% - 48px) / 4);
  border-radius: 20px;
  box-shadow: 0 0 30px 0 rgba(246, 248, 250, 0.6);
  border: solid 1px #f6f6f6;
  border: ${(props) => props.color || "solid 1px #f6f6f6"};
  background-color: #fff;
`;
const InquiryLabel = styled.h2`
  text-align: center;
  font-size: 26px;
  font-weight: 800;
  line-height: 1.5;
  color: ${(props) => props.color || "#111"};
  position: relative;
  padding: 20px 0;
  border-bottom: 1px solid #f6f8fa;
`;
const InquiryInformationWrap = styled.div`
  padding: 20px 10px 30px;
`;
const InquiryTextWrap1 = styled.div`
  padding-bottom: 20px;
`;
const BoostInquiryTextWrap2 = styled.div`
  min-height: 40px;
  margin-bottom: 20px;
`;
const BoostInquiryTextWrap3 = styled.div`
  min-height: 120px;
  margin-bottom: 20px;
`;
const BoostInquiryTextWrap4 = styled.div`
  min-height: 100px;
  margin-bottom: 20px;
`;
const BoostInquiryTextWrap5 = styled.div`
  min-height: 40px;
  margin-bottom: 20px;
`;
const InquiryTextWrap2 = styled.div`
  min-height: 133px;
  margin-bottom: 20px;
`;
const InquiryTextWrap3 = styled.div`
  margin-bottom: 40px;
  min-height: 41px;
`;
const InquiryCheckText = styled.p`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.3;
  color: #333;
  margin-bottom: ${(props) => props.margin || "0px"};
  &::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    background-image: url(${CheckImg});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 5px;
  }
`;
const InquiryText = styled.p`
  font-size: 14px;
  line-height: 1.3;
  color: #555;
  margin-bottom: 5px;
  padding-left: 13px;
`;
const DetailView = styled.a`
  display: block;
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
  color: #aaa;
  margin-bottom: 30px;
`;
const InquiryButtonWrap = styled.div`
  padding: 20px 14px 0;
  text-align: center;
`;
const InquiryPrice = styled.p`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  color: #111;
`;
const InquiryPeriod = styled.p`
  font-size: 14px;
  line-height: 1.5;
  color: #aaa;
  margin-bottom: 15px;
`;
const InquiryButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  border-radius: 10px;
  background-color: ${(props) => props.color || "#111"};
  color: #fff;
`;
// 질문 답변
const FAQ = styled.div`
  display: flex;
  padding: 30px 20px;
  border-radius: 20px;
  box-shadow: 0 0 30px 0 rgba(246, 248, 250, 0.6);
  border: solid 1px #f6f6f6;
  background-color: #fff;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 30px;
`;
const FAQWrap = styled.div``;
const Question = styled.p`
  color: #111;
  margin-bottom: 12px;
`;
const Answer = styled.p`
  font-size: 14px;
  color: #777;
`;
const Example = styled.p`
  font-size: 14px;
  color: #ccc;
  margin-top: 8px;
`;
// 페키지 가격표
const PackageTable = styled.div``;
const PackageThead = styled.ul`
  display: flex;
  width: 100%;
  height: 93px;
  border-radius: 20px;
  border: solid 1px #f6f6f6;
  background-color: #fff;
  margin-bottom: 20px;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
// PackageThead 모바일
const MbPackageThead = styled.div`
  display: none;
  @media screen and (max-width: 800px) {
    display: block;
    margin-bottom: 20px;
  }
`;
const MbPackageTheadTitle = styled.h2`
  font-size: 22px;
  font-weight: bold;
  color: #111;
  margin-bottom: 20px;
`;
const MbPackageTheadWrap = styled.div`
  width: 100%;
  overflow: auto;
`;
const MbPackageTheadUl = styled.ul`
  display: flex;
  gap: 8px;
  width: max-content;
`;
const MbPackageTheadLi = styled.li`
  padding: 5px 15px;
  border-radius: 5px;
  border: solid 1px #f6f6f6;
  background-color: #fff;
  color: #aaa;
  cursor: pointer;
  &:nth-child(${(props) => props.now - 1}) {
    font-weight: bold;
    background-color: #4480f7;
    color: #fff;
    border: unset;
  }
`;

const PackageTh = styled.li`
  display: flex;
  text-align: center;
  justify-content: ${(props) => props.left || "center"};
  align-items: center;
  width: calc(100% / 5);
  height: 100%;
  font-size: 22px;
  font-weight: bold;
  color: #111;
  padding: 0 28px;
  border-right: ${(props) => props.borderRight || "1px solid #edf2f7"};
`;
const PackageTbody = styled.div`
  border-radius: 20px;
  border: solid 1px #f6f6f6;
  background-color: #fff;
  overflow: hidden;
`;
const PackageTr = styled.ul`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #edf2f6;
`;
const PackageTbLabel = styled.li`
  width: calc(100% / 5);
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-weight: bold;
  line-height: 1.3;
  padding: 30px 8px 30px 28px;
  border-right: 1px solid #edf2f6;
  @media screen and (max-width: 800px) {
    width: 107px;
    padding: 20px 8px 20px 15px;
    font-size: 14px;
    justify-content: unset;
  }
`;
const AddExplanation = styled.p`
  font-size: 14px;
  line-height: 1.3;
  color: #555;
  font-weight: normal;
  @media screen and (max-width: 800px) {
    font-size: 12px;
    font-weight: normal;
  }
`;

const PackageTb = styled.li`
  width: calc(100% / 5);
  padding: 30px 8px 30px 28px;
  border-right: 1px solid #edf2f6;
  @media screen and (max-width: 800px) {
    display: none;
    &:nth-child(${(props) => props.now}) {
      display: block;
      width: calc(100% - 107px);
      padding: 20px 14px;
    }
  }
`;
const PackageTbCenter = styled(PackageTb)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: unset;
  @media screen and (max-width: 800px) {
    display: none;
    &:nth-child(${(props) => props.now}) {
      display: flex;
    }
  }
`;

const PackageTbText = styled.p`
  color: #555;
  line-height: 1.5;
  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`;
const PackageTbBoldText = styled(PackageTbText)`
  font-weight: bold;
  color: #111;
`;
const PackageTbPeriodText = styled.span`
  font-size: 14px;
  color: #555;
  font-weight: normal;
  @media screen and (max-width: 800px) {
    font-size: 12px;
  }
`;
const PackageTbSmallText = styled(PackageTbText)`
  font-size: ${(props) => props.fontSize || "14px"};
`;
const BlueCheck = styled.img`
  display: inline-block;
  max-width: 20px;
  @media screen and (max-width: 800px) {
    max-width: 16px;
  }
`;
const BlueCheck2 = styled.img`
  display: inline-block;
  max-width: 16px;
  margin-left: 8px;
`;

export default PriceGuide;
