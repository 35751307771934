import { Routes, Route, Link, useNavigate, Outlet } from 'react-router-dom';
import { useContext } from 'react';


import styled, {keyframes} from 'styled-components';

//components
import Header from '../components/Header'
import Footer from '../components/Footer'
import {RequestModal} from '../components/RequestModal' 

// 페이드인
import AOS from "aos";
import "aos/dist/aos.css";

// 스와이파
import 'swiper/css';
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { EffectFade, Pagination } from "swiper";
import { Autoplay } from "swiper";

// svg 이미지
import SolutionsImg1 from "../images/Main2/PC/SolutionsImg1.png";
import SolutionsImg2 from "../images/Main2/PC/SolutionsImg2.png";
import MbSolutionsImg1 from "../images/Main2/m/Group 19796.png";
import MbSolutionsImg2 from "../images/Main2/m/Group 19797.png";

import CampaignImg1 from "../images/Main2/PC/plentyplant.png";
import CampaignImg2 from "../images/Main2/PC/knotted.png";
import CampaignImg3 from "../images/Main2/PC/musinsa.png";
import CampaignImg4 from "../images/Main2/PC/fitamin.png";
import MbCampaignImg1 from "../images/Main2/m/m_plentytlant.png";
import MbCampaignImg2 from "../images/Main2/m/m-knotted.png";
import MbCampaignImg3 from "../images/Main2/m/m_musinsa.png";
import MbCampaignImg4 from "../images/Main2/m/m_fitamin.png";


import Differences1 from "../images/Main/Group 19568.svg";
import Differences2 from "../images/Main/calender-pc.png";

import Creator1 from "../images/Main/streamer1.png";
import Creator2 from "../images/Main/streamer2.svg";
import Creator3 from "../images/Main/streamer3.svg";
import Creator4 from "../images/Main/streamer4.svg";

//hook
import { useOverlay } from '../module/overlay/useOverlay'


function Main() {
    const {open} = useOverlay()
    let navigate = useNavigate();

    const openRequestModal = function (e) {
      e.preventDefault();
      open(({ isOpen, close, exit }) => {
        return (
          <RequestModal title={"컨설팅 신청"} isOpen={isOpen} onClose={exit} />
        );
      });
    };

    return (
      <Asd>
        {/* header */}
        <Header mainHeader={true}></Header>

        <Banner>
          <BannerLetterWrap>
            <BannerLetter>국내 1위 인플루언서 영향력 검증 솔루션</BannerLetter>
            <BannerBigLetter>가장 빠르고 저렴한</BannerBigLetter>
            <BannerBigLetterBlue>
            성과내는 인플루언서 마케팅
            </BannerBigLetterBlue>
            <BannerButton
              href="https://partner.creator.ly"
              onClick={openRequestModal}
            >
              견적 문의하기
            </BannerButton>
          </BannerLetterWrap>
          <BannerBackground>
            <AnimationItemIcon
              left={"13%"}
              delay={"-1s"}
              mbdisplay
              mbLeft={"1%"}
            >
              <AnimationItemIconImg
                src={require("../images/BannerIcon/Group 19775.png")}
              ></AnimationItemIconImg>
            </AnimationItemIcon>

            <AnimationItemIcon
              left={"17%"}
              delay={"-5s"}
              mbdisplay
              mbLeft={"6%"}
            >
              <AnimationItemIconImg
                src={require("../images/BannerIcon/Group 19775.png")}
              ></AnimationItemIconImg>
            </AnimationItemIcon>

            <AnimationItem
              left={"24%"}
              delay={"4s"}
              opacity={"0.6"}
              mbdisplay
              mbLeft={"20%"}
            ></AnimationItem>

            <AnimationItemIcon left={"27%"} delay={"-9s"} width={"83px"}>
              <AnimationItemIconImg
                src={require("../images/BannerIcon/Group 19776.png")}
              ></AnimationItemIconImg>
            </AnimationItemIcon>

            <AnimationItem
              left={"35%"}
              delay={"-6s"}
              mbdisplay
              mbLeft={"24%"}
            ></AnimationItem>

            <AnimationItemIcon left={"42%"} delay={"-5s"} width={"83px"}>
              <AnimationItemIconImg
                src={require("../images/BannerIcon/Group 19776.png")}
              ></AnimationItemIconImg>
            </AnimationItemIcon>

            <AnimationItemIcon left={"46%"} delay={"-7s"} width={"59px"}>
              <AnimationItemIconImg
                src={require("../images/BannerIcon/Group 19778.png")}
              ></AnimationItemIconImg>
            </AnimationItemIcon>

            <AnimationItem left={"62%"} delay={"-8s"}></AnimationItem>

            <AnimationItemIcon
              left={"70%"}
              delay={"-6s"}
              width={"113px"}
              mbdisplay
              mbLeft={"85%"}
            >
              <AnimationItemIconImg
                src={require("../images/BannerIcon/Group 19779.png")}
              ></AnimationItemIconImg>
            </AnimationItemIcon>

            <AnimationItem
              left={"77%"}
              delay={"-4s"}
              opacity={"0.2"}
            ></AnimationItem>

            <AnimationItemIcon left={"85%"} delay={"-1s"} width={"55px"}>
              <AnimationItemIconImg
                src={require("../images/BannerIcon/Group 19777.png")}
              ></AnimationItemIconImg>
            </AnimationItemIcon>

            <AnimationItem left={"90%"} delay={"-5s"}></AnimationItem>
          </BannerBackground>
        </Banner>

        <CooperationBannerWrap>
          <CooperationBannerMb />
          <CooperationBanner>
            <CooperationBannerItemWrap>
              <CooperationBannerItem1>
                <CooperationBannerimg
                  src={require("../images/Main_Mobile1/line1.png")}
                />
              </CooperationBannerItem1>
              <CooperationBannerItem1>
                <CooperationBannerimg
                  src={require("../images/Main_Mobile1/line1.png")}
                />
              </CooperationBannerItem1>
            </CooperationBannerItemWrap>

            <CooperationBannerItemWrap>
              <CooperationBannerItem2>
                <CooperationBannerimg
                  src={require("../images/Main_Mobile1/line2.png")}
                />
              </CooperationBannerItem2>
              <CooperationBannerItem2Left>
                <CooperationBannerimg
                  src={require("../images/Main_Mobile1/line2.png")}
                />
              </CooperationBannerItem2Left>
            </CooperationBannerItemWrap>
          </CooperationBanner>
        </CooperationBannerWrap>

        <Figure>
          <Container>
            <Title>
              <Icon src={require("../images/Main_Mobile1/Chart bar.png")} />
              체계적인 데이터 관리
            </Title>
            <Additional>우리는 수치로 증명합니다</Additional>
            <FigureItemWrap>
              <FigureItem data-aos="zoom-in" data-aos-delay="0">
                <FigureImg
                  src={require("../images/Main_Mobile1/Group 19518.png")}
                />
                <FigureNum>11,682 +</FigureNum>
                <FigureTitle>누적 캠페인 수</FigureTitle>
              </FigureItem>
              <FigureItem data-aos="zoom-in" data-aos-delay="200">
                <FigureImg
                  src={require("../images/Main_Mobile1/Group 19519.png")}
                />
                <FigureNum>1,589,543 +</FigureNum>
                <FigureTitle>누적 콘텐츠 제작 수</FigureTitle>
              </FigureItem>
              <FigureItem data-aos="zoom-in" data-aos-delay="400">
                <FigureImg
                  src={require("../images/Main_Mobile1/Group 19520.png")}
                />
                <FigureNum>6,341 +</FigureNum>
                <FigureTitle>누적 파트너 수</FigureTitle>
              </FigureItem>
              <FigureItem data-aos="zoom-in" data-aos-delay="600">
                <FigureImg
                  src={require("../images/Main_Mobile1/Group 19521.png")}
                />
                <FigureNum>74%</FigureNum>
                <FigureTitle>재계약률</FigureTitle>
              </FigureItem>
            </FigureItemWrap>
          </Container>
        </Figure>

        <WinningExperience>
          <Container data-aos="fade-down">
            <Title>
              <Icon src={require("../images/Main_Mobile1/Sparkles.png")} />
              우승 경험
            </Title>
            <Additional>1등엔 이유가 있습니다</Additional>
            <WinningItemWrap>
              <WinningItem
                src={require("../images/About_PC/Group 19800.png")}
              />
              <WinningItem
                src={require("../images/About_PC/Group 19801.png")}
              />
              <WinningItem
                src={require("../images/About_PC/Group 19802.png")}
              />
            </WinningItemWrap>
            <MbWinningItemWrap>
              <WinningItem
                src={require("../images/Main_Mobile1/Group 19564.png")}
              />
              <WinningItem
                src={require("../images/Main_Mobile1/Group 19565.png")}
              />
              <WinningItem
                src={require("../images/Main_Mobile1/Group 19566.png")}
              />
            </MbWinningItemWrap>
          </Container>
        </WinningExperience>

        <Solutions>
          <Container data-aos="fade-down">
            <Additional>
              국내 1위 인플루언서 영향력 검증 <br />
              성과내는 마케팅 솔루션입니다
            </Additional>
            <SolutionsItemWrap>
              <SolutionsItem>
                <SolutionsImg src={SolutionsImg1} />
                <MbSolutionsImg src={MbSolutionsImg1} />
              </SolutionsItem>
              <SolutionsItem>
                <SolutionsImg src={SolutionsImg2} />
                <MbSolutionsImg src={MbSolutionsImg2} />
              </SolutionsItem>
            </SolutionsItemWrap>
          </Container>
        </Solutions>

        <Campaign>
          <Container data-aos="fade-down">
            <Title>
              <Icon
                src={require("../images/Main_Mobile1/Document report.png")}
              />
              캠페인 진행 사례
            </Title>
            <Additional>이미 6,341+ 브랜드가 효과를 경험했습니다</Additional>
            <SmallAdditional>
              크리에이터리 팀은 고객사의 성공과 만족을 최우선 원칙으로 하고
              있습니다
            </SmallAdditional>
            <CampaignItemWrap>
              <CampaignItem>
                <CampaignImg src={CampaignImg1} />
                <CampaignButtonWrap>
                  <CampaignButtonLeft>
                    <CampaignButtonLeftP>도달당 비용</CampaignButtonLeftP>
                    <CampaignButtonLeftCPR color="#49c87e">
                      CPR 37원
                    </CampaignButtonLeftCPR>
                  </CampaignButtonLeft>
                  <CampaignButton
                    onClick={openRequestModal}
                    href="https://partner.creator.ly"
                    background="#49c87e"
                    hover="#3cac6b"
                  >
                    같은 견적 문의하기
                  </CampaignButton>
                </CampaignButtonWrap>
              </CampaignItem>
              <CampaignItem>
                <CampaignImg src={CampaignImg2} />
                <CampaignButtonWrap>
                  <CampaignButtonLeft>
                    <CampaignButtonLeftP>도달당 비용</CampaignButtonLeftP>
                    <CampaignButtonLeftCPR color="#FA986D">
                      CPR 13원
                    </CampaignButtonLeftCPR>
                  </CampaignButtonLeft>
                  <CampaignButton
                    onClick={openRequestModal}
                    href="https://partner.creator.ly"
                    background="#FA986D"
                    hover="#fc7c44"
                  >
                    같은 견적 문의하기
                  </CampaignButton>
                </CampaignButtonWrap>
              </CampaignItem>
              <CampaignItem>
                <CampaignImg src={CampaignImg3} />
                <CampaignButtonWrap>
                  <CampaignButtonLeft>
                    <CampaignButtonLeftP>도달당 비용</CampaignButtonLeftP>
                    <CampaignButtonLeftCPR color="#7b52f7">
                      CPR 35원
                    </CampaignButtonLeftCPR>
                  </CampaignButtonLeft>
                  <CampaignButton
                    onClick={openRequestModal}
                    href="https://partner.creator.ly"
                    background="#7b52f6"
                    hover="#6843d9"
                  >
                    같은 견적 문의하기
                  </CampaignButton>
                </CampaignButtonWrap>
              </CampaignItem>
              <CampaignItem>
                <CampaignImg src={CampaignImg4} />
                <CampaignButtonWrap>
                  <CampaignButtonLeft>
                    <CampaignButtonLeftP>도달당 비용</CampaignButtonLeftP>
                    <CampaignButtonLeftCPR color="#3e94fb">
                      CPR 275원
                    </CampaignButtonLeftCPR>
                  </CampaignButtonLeft>
                  <CampaignButton
                    onClick={openRequestModal}
                    href="https://partner.creator.ly"
                    background="#3e94fb"
                    hover="#3483e1"
                  >
                    같은 견적 문의하기
                  </CampaignButton>
                </CampaignButtonWrap>
              </CampaignItem>
            </CampaignItemWrap>

            <MbCampaignItemWrap>
              <Swiper
                pagination={true}
                modules={[Autoplay, Pagination]}
                spaceBetween={20}
                slidesPerView={1}
                loop={false}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
              >
                <SwiperSlide>
                  <CampaignImg src={MbCampaignImg1} />
                  <CampaignButtonWrap>
                    <CampaignButtonLeft>
                      <CampaignButtonLeftP>도달당 비용</CampaignButtonLeftP>
                      <CampaignButtonLeftCPR color="#49c87e">
                        CPR 37원
                      </CampaignButtonLeftCPR>
                    </CampaignButtonLeft>
                    <CampaignButton
                      onClick={openRequestModal}
                      href="https://partner.creator.ly"
                      background="#49c87e"
                      hover="#3cac6b"
                    >
                      같은 견적 문의하기
                    </CampaignButton>
                  </CampaignButtonWrap>
                </SwiperSlide>
                <SwiperSlide>
                  <CampaignImg src={MbCampaignImg2} />
                  <CampaignButtonWrap>
                    <CampaignButtonLeft>
                      <CampaignButtonLeftP>도달당 비용</CampaignButtonLeftP>
                      <CampaignButtonLeftCPR color="#FA986D">
                        CPR 13원
                      </CampaignButtonLeftCPR>
                    </CampaignButtonLeft>
                    <CampaignButton
                      onClick={openRequestModal}
                      href="https://partner.creator.ly"
                      background="#FA986D"
                      hover="#fc7c44"
                    >
                      같은 견적 문의하기
                    </CampaignButton>
                  </CampaignButtonWrap>
                </SwiperSlide>
                <SwiperSlide>
                  <CampaignImg src={MbCampaignImg3} />
                  <CampaignButtonWrap>
                    <CampaignButtonLeft>
                      <CampaignButtonLeftP>도달당 비용</CampaignButtonLeftP>
                      <CampaignButtonLeftCPR color="#7b52f7">
                        CPR 35원
                      </CampaignButtonLeftCPR>
                    </CampaignButtonLeft>
                    <CampaignButton
                      onClick={openRequestModal}
                      href="https://partner.creator.ly"
                      background="#7b52f6"
                      hover="#6843d9"
                    >
                      같은 견적 문의하기
                    </CampaignButton>
                  </CampaignButtonWrap>
                </SwiperSlide>
                <SwiperSlide>
                  <CampaignImg src={MbCampaignImg4} />
                  <CampaignButtonWrap>
                    <CampaignButtonLeft>
                      <CampaignButtonLeftP>도달당 비용</CampaignButtonLeftP>
                      <CampaignButtonLeftCPR color="#3e94fb">
                        CPR 275원
                      </CampaignButtonLeftCPR>
                    </CampaignButtonLeft>
                    <CampaignButton
                      onClick={openRequestModal}
                      href="https://partner.creator.ly"
                      background="#3e94fb"
                      hover="#3483e1"
                    >
                      같은 견적 문의하기
                    </CampaignButton>
                  </CampaignButtonWrap>
                </SwiperSlide>
              </Swiper>
            </MbCampaignItemWrap>
          </Container>
        </Campaign>

        <Differences>
          <Container data-aos="fade-down">
            <Title>
              <Icon src={require("../images/Main_Mobile1/Scale.png")} />
              차별점
            </Title>
            <Additional>가장 저렴하고 효율적인</Additional>
            <Additional>인플루언서 마케팅 솔루션</Additional>
            <ContainerItemWrap>
              <ContainerItem>
                <MbContainerImg src={Differences1}></MbContainerImg>
                <ContainerItemPWrap>
                  <BigContainerItemP>타사대비 76% 저렴</BigContainerItemP>
                  <ContainerItemP>
                    우리는 박리다매 전략을 갖고 있습니다. 압도적으로 저렴한 가격
                    구성으로 고객사가 부담이 되지 않도록 운영하며,
                    인플루언서에게도 여러 광고 캠페인을 제공해 수입을 증가시키는
                    것이 목적입니다.
                  </ContainerItemP>
                </ContainerItemPWrap>
                <ContainerImg src={Differences1}></ContainerImg>
              </ContainerItem>

              <ContainerItem>
                <ContainerImg src={Differences2}></ContainerImg>
                <MbContainerImg src={Differences2}></MbContainerImg>
                <ContainerItemPWrap>
                  <BigContainerItemP>인플루언서 모집 기간</BigContainerItemP>
                  <ContainerItemP>
                    국내 3만여 명 인플루언서에게 개인 SNS계정을 분석한 리포트를
                    매일 밤마다 카카오톡으로 제공합니다. 80%가 이를 매일
                    확인하고 있습니다. 우리는 인플루언서에게 분석 리포트를
                    제공할 때 협찬 제안을 함께 전달합니다.
                  </ContainerItemP>
                </ContainerItemPWrap>
              </ContainerItem>
            </ContainerItemWrap>
          </Container>
        </Differences>

        <Creator data-aos="fade-down">
          <Title>
            <Icon src={require("../images/Main_Mobile1/Emoji happy.png")} />
            크리에이터
          </Title>
          <Additional margin_bottom={"8px"}>
            크리에이터리 맞춤 솔루션을 통해
          </Additional>
          <Additional margin_bottom={"60px"}>
            다양한 인플루언서들을 만나보세요
          </Additional>

          <CooperationBanner>
            <CooperationBannerItemWrap>
              <CooperationBannerItem1>
                <CooperationBannerimg src={Creator1} />
              </CooperationBannerItem1>
              <CooperationBannerItem1>
                <CooperationBannerimg src={Creator1} />
              </CooperationBannerItem1>
            </CooperationBannerItemWrap>

            <CooperationBannerItemWrap>
              <CooperationBannerItem2>
                <CooperationBannerimg src={Creator2} />
              </CooperationBannerItem2>
              <CooperationBannerItem2Left>
                <CooperationBannerimg src={Creator2} />
              </CooperationBannerItem2Left>
            </CooperationBannerItemWrap>

            <CooperationBannerItemWrap>
              <CooperationBannerItem1>
                <CooperationBannerimg src={Creator3} />
              </CooperationBannerItem1>
              <CooperationBannerItem1>
                <CooperationBannerimg src={Creator3} />
              </CooperationBannerItem1>
            </CooperationBannerItemWrap>

            <CooperationBannerItemWrap>
              <CooperationBannerItem2>
                <CooperationBannerimg src={Creator4} />
              </CooperationBannerItem2>
              <CooperationBannerItem2Left>
                <CooperationBannerimg src={Creator4} />
              </CooperationBannerItem2Left>
            </CooperationBannerItemWrap>
          </CooperationBanner>
        </Creator>

        <Possibility data-aos="fade-down">
          <PossibilityBg
            src={require("../images/About_PC/L36A3554 1.png")}
          ></PossibilityBg>
          <PossibilityBgFilter></PossibilityBgFilter>
          <Container>
            <PossibilityLetter>어떻게 이게 가능할까요?</PossibilityLetter>
            <SmallPossibilityLetter>
              크리에이터리 팀은 크리에이터를 위한 소프트웨어를 개발하는
              IT기업입니다
            </SmallPossibilityLetter>
            <SmallPossibilityLetter>
              마케팅 성과와 기술력을 인정받아
              <SmallPossibilityLetterBold>
                {" "}
                인스타그램과 유튜브 본사와 데이터 파트너십을 맺었습니다
              </SmallPossibilityLetterBold>
            </SmallPossibilityLetter>
            <PossibilityButton
              onClick={() => {
                navigate("/About");
              }}
            >
              회사 소개
            </PossibilityButton>
          </Container>
        </Possibility>

        <Footer />
      </Asd>
    );
}


const Asd = styled.div`
    overflow: hidden;
`

// 컨테이너
const Container = styled.div` width: 100%; max-width: 1050px; margin: 0 auto;
    @media screen and (max-width: 980px) {
        padding: 0 16px;
    }
`;
// 타이틀
const Title = styled.p`
    font-size: 24px;
    font-weight: bold;
    color: #a3b0bb;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-bottom: 20px;
    @media screen and (max-width: 880px) {
        font-size: 18px;
        margin-bottom: 15px;
    }
`
// 타이틀 아이콘
const Icon = styled.img`
    display: inline-block;
    max-width: 28px;
    @media screen and (max-width: 880px) {
        max-width: 20px;
    }
`
// 타이틀 아래 글씨
const Additional = styled.p`
    font-size: 46px;
    font-weight: 800;
    color: #222222;
    text-align: center;
    margin-bottom: ${(props) => props.margin_bottom || "0px"};
    @media screen and (max-width: 880px) {
        font-size: 24px;
    }
`;



// section1=========================================================================
const Banner = styled.div` 
    position: relative;
    height: 920px; 
    background-color: #081525;
    padding-top: 230px;
    @media screen and (max-width: 800px) {
        height: 400px;
        padding-top: 100px;
    }
`;
const BannerLetterWrap = styled.div`
    position: relative;
    text-align: center;
    z-index: 1;
`
const BannerLetter = styled.p` 
    opacity: 0.6; 
    font-size: 24px; 
    color: #fff; 
    margin-bottom: 15px;
    @media screen and (max-width: 800px) {
        font-size: 16px;
    }
`;
const BannerBigLetter = styled.p`
    font-size: 70px;
    font-weight: 800;
    color: #fff;
    margin-bottom: 10px;
    @media screen and (max-width: 800px) {
        font-size: 28px;
    }
`
const BannerBigLetterBlue = styled(BannerBigLetter)`
    color: #4480f7;
    margin-bottom: 60px;
`;
const BannerButton = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 262px;
    height: 80px;
    border-radius: 5px;
    background-color: #4480f7;
    font-size: 24px;
    color: #fff;
    font-weight: bold;
    transition: all 0.3s;
    @media screen and (max-width: 800px) {
        height: 56px;
        font-size: 18px;
    }
    &:hover{
        background-color : #296ff9;
    }
`;
const BannerBackground = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
`;

const rotate = keyframes` /* 2. css코드를 씀. */
    0%{
        top: 100%;
    }
    100%{
        top: -100px;
    }
`;
const AnimationItem = styled.div`
    position: absolute;
    width: 27px;
    height: 27px;
    border-radius: 100%;
    background-color: #4480f7;
    top: 100%;
    left: ${(props) => props.left || "0px"};
    opacity: ${(props) => props.opacity || "1"};
    animation: ${rotate};
    animation-duration: 7s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-play-state: running;
    animation-delay: ${(props) => props.delay || "0s"};
    @media screen and (max-width: 800px) {
        display: ${(props) => props.mbdisplay || "none"};
        left: ${(props) => props.mbLeft || "0px"};
        width: 14px;
        height: 14px;
    }
`
const AnimationItemIcon = styled(AnimationItem)`
    width: ${(props) => props.width || "61px"};
    height: auto;
    background-color: unset;
    @media screen and (max-width: 800px) {
        width: 36px;
    }
`;
const AnimationItemIconImg = styled.img`
    width: 100%;
`

// section2===================================================================================
const CooperationBannerWrap = styled.div`
    position: relative;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
    @media screen and (max-width: 800px) {
        padding: 20px 0;
    }
`;
const CooperationBannerMb = styled.div`
    display: none;
    width: 100%;
    height: 20px;
    background-color: #fff;
    position: absolute;
    bottom: 100%;
    border-radius: 20px 20px 0 0;
    @media screen and (max-width: 800px) {
        display: block;
    }
`
const CooperationBanner = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100vw;
    position: relative;
    @media screen and (max-width: 800px) {
        gap: 16px;
    }
`;
const CooperationBannerItemWrap = styled.div`
    width: calc(100vw * 2);
    display: flex;
    @media screen and (max-width: 1290px) {
        width: calc(1290px * 2);
    }
`;



const CooperationAnimation1 = keyframes` /* 2. css코드를 씀. */
    0%{
        transform: translateX(0);
    }
    100%{
        transform: translateX(-100%);
    }
`;
const CooperationAnimation2 = keyframes` /* 2. css코드를 씀. */
    0%{
        transform: translateX(0);
    }
    100%{
        transform: translateX(100%);
    }
`;
const CooperationBannerItem1 = styled.div`
    width: 50%;
    animation: ${CooperationAnimation1};
    animation-duration: 30s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-play-state: running;
    padding-right: 40px;
`;
const CooperationBannerItem2 = styled.div`
    width: 50%;
    animation: ${CooperationAnimation2};
    animation-duration: 30s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-play-state: running;
    padding-right: 40px;
`;
const CooperationBannerItem2Left = styled(CooperationBannerItem2)`
    position: absolute;
    width: 100%;
    right: calc(100%);
    @media screen and (max-width: 1290px) {
        width: 1290px;
    }
`;
const CooperationBannerimg = styled.img``

// section3============================================================
const Figure = styled.div`
    padding: 150px 0 160px;
    @media screen and (max-width: 880px) {
        padding: 110px 0 80px;
    }
`
const FigureItemWrap = styled.ul`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 40px 0;
    padding-top: 60px;
    @media screen and (max-width: 880px) {
        justify-content: center;
        padding-top: 30px;
    }
`;
const FigureItem = styled.li`
    max-width: calc(100% / 4);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 880px) {
        max-width: 300px;
        width: calc(100% / 2);
    }
`
const FigureImg = styled.img`
    max-width: 200px;
    margin-bottom: 30px;
    @media screen and (max-width: 880px) {
        max-width: 120px;
    }
`
const FigureNum = styled.p`
    font-size: 36px;
    font-family: 'GmarketSans';
    color: #111;
    font-weight: bold;
    margin-bottom: 24px;
    @media screen and (max-width: 880px) {
        font-size: 20px;
    }
`
const FigureTitle = styled.div`
    display: flex;
    padding: 8px 15px;
    background-color: #4480f71A;
    border-radius: 20px;
    font-size: 20px;
    color: #4480f7;
    @media screen and (max-width: 880px) {
        font-size: 14px;
        padding: 6px 10px;
    }
`
// section4============================================================================
const WinningExperience = styled.div`
    padding: 150px 0;
    @media screen and (max-width: 880px) {
        padding: 80px 0;
    }
`
const WinningItemWrap = styled.div`
    display: flex;
    gap: 24px;
    padding-top: 60px;
    @media screen and (max-width: 640px) {
        display: none;
    }
`
const WinningItem = styled.img`
    width: calc((100% - 48px) / 3);
    @media screen and (max-width: 640px) {
        width: 100%;
        max-width: 300px;
    }
`
const MbWinningItemWrap = styled.div`
    display: none;
    align-items: center;
    flex-direction: column;
    padding-top: 30px;
    gap: 15px;
    @media screen and (max-width: 640px) {
        display: flex;
    }
`
// section5============================================================================
const Solutions = styled.div`
    padding: 150px 0;
    background-color: #f6f8fa;
    @media screen and (max-width: 880px) {
        padding: 80px 0;
    }
`
const SolutionsItemWrap = styled.ul`
    display: flex;
    gap: 16px;
    padding-top: 61px;
    @media screen and (max-width: 880px) {
        flex-direction: column;
        align-items: center;
        padding-top: 30px;
        gap: 20px;
    }
`
const SolutionsItem = styled.li`
    position: relative;
    width: calc((100% - 16px) / 2);
    border-radius: 20px;
    display: flex;
    align-items: flex-end;
    @media screen and (max-width: 880px) {
        width: 100%;
        max-width: 400px;
    }
`
const SolutionsImg = styled.img`
    display: block;
    @media screen and (max-width: 880px) {
        display: none;
    }
`
const MbSolutionsImg = styled.img`
    display: none;
    @media screen and (max-width: 880px) {
        display: block;
    }
`
// const SolutionsCircle1 = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;
//     gap: 4px;
//     width: 113px;
//     height: 113px;
//     border-radius: 100%;
//     background-color: #d5dcf9;
//     position: absolute;
//     top: -22px;
//     right: 22px;
// `
// const SolutionsCircle1_p = styled.p`
//     font-size: 14px;
//     color: #4f5dec;
// `
// const SolutionsCircle1_num = styled.p`
//     font-size: 26px;
//     color: #4f5dec;
//     font-weight: bold;
// `

// const SolutionsCircle2 = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;
//     gap: 4px;
//     width: 155px;
//     height: 155px;
//     border-radius: 100%;
//     background-color: #4f5dec;
//     position: absolute;
//     top: -43px;
//     right: 20px;
// `
// const SolutionsCircle2_p = styled.p`
//     font-size: 14px;
//     color: #4f5dec;
// `
// const SolutionsCircle2_num = styled.p`
//     font-size: 26px;
//     color: #4f5dec;
//     font-weight: bold;
// `

// const SolutionsTop = styled.p`
//     font-size: 28px;
//     font-weight: bold;
//     color: #4c4c4c;
//     height: 35px;
//     margin-bottom: 25px;
// `
// const SolutionsCenter = styled.div`

// `
// const SolutionsBottom = styled.p`
//     font-size: 18px;
//     color: #4c4c4c;
// `

// section6============================================================================
const Campaign = styled.div`
    padding: 147px 0 151px;
    @media screen and (max-width: 880px) {
        padding: 80px 0;
    }
`
const SmallAdditional = styled.p`
    font-size: 20px;
    opacity: 0.6;
    color: #000;
    font-size: 20px;
    margin-top: 15px;
    text-align: center;
    @media screen and (max-width: 880px) {
        font-size: 16px;
    }
`
const CampaignItemWrap = styled.ul`
    padding-top: 60px;
    display: flex;
    gap: 30px 16px;
    flex-wrap: wrap;
    @media screen and (max-width: 880px) {
        display: none;
    }
`
const CampaignItem = styled.li`
    width: calc((100% - 16px) / 2);
    position: relative;
`
const CampaignButtonWrap = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    position: absolute;
    bottom: 0;
    left: 0;

    @media screen and (max-width: 880px) {
        padding: 30px 15px;
    }
`
const CampaignButtonLeft = styled.div``
const CampaignButtonLeftP = styled.p`
    font-size: 14px;
    color: #777;
    @media screen and (max-width: 880px) {
        font-size: 12px;
    }
`
const CampaignButtonLeftCPR = styled.p`
    font-size: 26px;
    color: ${(props) => props.color || "#49c87e"};
    font-weight: bold;
    @media screen and (max-width: 880px) {
        font-size: 20px;
    }
`
const CampaignButton = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 176px;
    height: 54px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    border-radius: 5px;
    transition: all 0.3s;
    background-color: ${(props) => props.background || "#49c87e"};
    &:hover{
        background-color : ${(props) => props.hover || "#49c87e"};
    }
    @media screen and (max-width: 880px) {
        width: 134px;
        height: 39px;
        font-size: 14px;
    }
`
const MbCampaignItemWrap = styled.div`
    display: none;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding-top: 40px;
    @media screen and (max-width: 880px) {
        display: block;
    }
`
const CampaignImg = styled.img``


// section7============================================================================
const Differences = styled.div`
    padding: 150px 0;
    background-color: #fdfdfd;
    @media screen and (max-width: 880px) {
        padding: 80px 0;
    }
`
const ContainerItemWrap = styled.div`
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    @media screen and (max-width: 880px) {
        padding-top: 30px;
    }
`
const ContainerItem = styled.div`
    padding: 60px 36px;
    border-radius: 20px;
    box-shadow: 0 0 30px 0 rgba(246, 248, 250, 0.6);
    border: solid 1px #f6f6f6;
    background-color: #fff;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    @media screen and (max-width: 880px) {
        flex-direction: column;
        padding: 30px 15px;
    }
`
const ContainerItemPWrap = styled.div``
const BigContainerItemP = styled.p`
    font-size: 40px;
    font-weight: 800;
    color: #111;
    margin-bottom: 20px;
    @media screen and (max-width: 880px) {
        font-size: 20px;
        margin-bottom: 10px;
    }
`
const ContainerItemP = styled.p`
    opacity: 0.6;
    font-size: 18px;
    line-height: 1.6;
    color: #000;
    max-width: 510px;
    @media screen and (max-width: 880px) {
        font-size: 16px;
    }
`
const ContainerImg = styled.img`
    max-width: 420px;
    display: unset;
    @media screen and (max-width: 880px) {
        display: none;
    }
`
const MbContainerImg = styled.img`
    max-width: 300px;
    display: none;
    @media screen and (max-width: 880px) {
        display: unset;
    }
`

// section8============================================================================
const Creator = styled.div`
    padding: 150px 0;
    @media screen and (max-width: 880px) {
        padding: 80px 0;
    }
`
// section9============================================================================
const Possibility = styled.div`
    position: relative;
    padding: 360px 0;
    @media screen and (max-width: 880px) {
        padding: 60px 0;
    }
`
const PossibilityBg = styled.img`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
    object-position: center;
`
const PossibilityBgFilter = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background-color: #000;
    z-index: -1;
`


const PossibilityLetter = styled.p`
    font-size: 40px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    margin-bottom: 26px;
    @media screen and (max-width: 880px) {
        font-size: 20px;
    }
`
const SmallPossibilityLetter = styled.p`
    font-size: 26px;
    color: #fff;
    text-align: center;
    margin-bottom: 16px;
    @media screen and (max-width: 880px) {
        font-size: 14px;
        margin-bottom: unset;
        line-height: 1.6;
    }
`
const SmallPossibilityLetterBold = styled.span`
    color: #fff;
    font-weight: bold;
`
const PossibilityButton = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    margin: 0 auto;
    width: 176px;
    height: 54px;
    border-radius: 5px;
    background-color: #4480f7;
    transition: all 0.3s;
    margin-top: 44px;
    cursor: pointer;
    &:hover{
        background-color : #296ff9;
    }
    @media screen and (max-width: 880px) {
        width: 134px;
        height: 39px;
        font-size: 14px;
        margin-top: 30px;
    }
`


export default Main;

