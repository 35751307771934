import { Routes, Route, Link, useNavigate, Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';


import styled, {keyframes} from 'styled-components';

import Header from '../components/Header'
import Footer from '../components/Footer'

// 페이드인
import AOS from "aos";
import "aos/dist/aos.css";

// 스와이파
import 'swiper/css';
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { EffectFade, Pagination } from "swiper";
import { Autoplay } from "swiper";


function About() {
    let navigate = useNavigate();
    // useEffect(() => {
    //     AOS.init();
    // })

    return (
        <>
            {/* header */}
            <Header mainHeader={false}></Header>

            <Banner>
                <BannerLetterWrap>
                    <Container>
                        <BannerBigLetter data-aos="fade-down">인플루언서 마케팅</BannerBigLetter>
                        <BannerBigLetterBlue data-aos="fade-down">성과내기 어려우셨나요?</BannerBigLetterBlue>
                        <TalkWrap>
                            <TalkItem data-aos="zoom-in" data-aos-delay="300">
                                <People1 src={require("../images/About_PC/Group 19841.png")}/>
                                <SpeechBubble1 src={require("../images/About_PC/SpeechBubble1.png")}></SpeechBubble1>
                                <MbSpeechBubble1 src={require("../images/About_PC/MbSpeechBubble1.png")}></MbSpeechBubble1>
                            </TalkItem>
                            <TalkItem data-aos="zoom-in" data-aos-delay="700">
                                <SpeechBubble2 src={require("../images/About_PC/SpeechBubble2.png")}></SpeechBubble2>
                                <MbSpeechBubble2 src={require("../images/About_PC/MbSpeechBubble2.png")}></MbSpeechBubble2>
                                <People2 src={require("../images/About_PC/Group 19842.png")}/>
                            </TalkItem>
                            <TalkItem data-aos="zoom-in" data-aos-delay="1100">
                                <People1 src={require("../images/About_PC/image 1724.png")}/>
                                <SpeechBubble1 src={require("../images/About_PC/SpeechBubble3.png")}></SpeechBubble1>
                                <MbSpeechBubble1 src={require("../images/About_PC/MbSpeechBubble3.png")}></MbSpeechBubble1>
                            </TalkItem>
                        </TalkWrap>
                    </Container>
                </BannerLetterWrap>
                <BannerBackground>

                    <AnimationItemIcon left={"24%"} delay={"-5s"} mbdisplay mbLeft={"20%"}>
                        <AnimationItemIconImg src={require("../images/About_PC/Group 19790.png")}></AnimationItemIconImg>
                    </AnimationItemIcon>

                    <AnimationItem left={"38%"} delay={"-5.5s"} opacity={"0.6"} mbdisplay mbLeft={"40%"}></AnimationItem>

                    <AnimationItem left={"70%"} delay={"-7s"} mbdisplay mbLeft={"63%"}></AnimationItem>

                    <AnimationItemIcon left={"77%"} delay={"-6s"} width={"83px"} mbdisplay mbLeft={"70%"}>
                        <AnimationItemIconImg src={require("../images/About_PC/Group 19789.png")}></AnimationItemIconImg>
                    </AnimationItemIcon>

                </BannerBackground>
            </Banner>

            <Difference>
                <Container >
                    <DifferenceWrap>
                        <DifferencTop data-aos="fade-down" data-aos-anchor-placement="top-center">
                            <DifferencTopLogo src={require("../images/logo-w.png")} />
                            <DifferencTopLetter>어떻게 다른가요?</DifferencTopLetter>
                        </DifferencTop>
                        <DifferencBox data-aos="fade-down" data-aos-anchor-placement="top-center">
                            <BigDifferencLetter>마케팅 성과와 기술력을 인정받아 <br />인스타그램, 유튜브 본사와 파트너십을 맺었습니다</BigDifferencLetter>
                            <DifferencLetter>인스타그램, 유튜브로부터 원본 데이터 토큰(API)을 받습니다</DifferencLetter>
                            <DifferenceImg src={require("../images/About_PC/Group 19816.png")} />
                            <MvDifferenceImg src={require("../images/About_PC/Group 19825.png")} />
                        </DifferencBox>
                        <DifferencBox data-aos="fade-down" data-aos-anchor-placement="top-center">
                            <BigDifferencLetter>인플루언서의 팔로워군을 <br />알고리즘으로 정확하게 분석하고 있습니다</BigDifferencLetter>
                            <DifferenceImg src={require("../images/About_PC/Group 19817.png")} />
                            <MvDifferenceImg src={require("../images/About_PC/MbGroup 19817.png")} />
                        </DifferencBox>
                    </DifferenceWrap>
                </Container>
            </Difference>

            <Kakao>
                <Container>
                    <Additional>답장이 느린 DM이 아닌 <br /> 인플루언서 개인 카카오톡으로 제안을 보냅니다</Additional>
                    <SmallAdditional>인플루언서는 쏟아지는 DM창을 잘 확인하지 못하며 스팸함으로 넘어가기도 합니다</SmallAdditional>
                    <KakaoWrap>
                        <KakaoBox>
                            <KakaoContentWrap>
                                <KakaoIcom src={require("../images/Main_Mobile2/Group 19590.png")} />
                                <KakaoTitle>인플루언서 DM</KakaoTitle>
                                <KakaoContent>
                                    광고 제안할 때 100명에게 DM을 보내면 1명에게 답이 오는 수준이에요. 또 커뮤니케이션하고 계약서 쓰고...번거로워요!”
                                </KakaoContent>
                                <Line></Line>
                            </KakaoContentWrap>

                            <KakaoImgWrap>
                                <KakaoImg src={require("../images/Main_Mobile2/KakaoImg1.png")} />
                                <KakaoImg src={require("../images/Main_Mobile2/KakaoImg2.png")} />
                            </KakaoImgWrap>

                            <KakaoBottom>
                                <KakaoBottomPercent>응답률 2% 미만</KakaoBottomPercent>
                                <KakaoBottomContent>인스타그램 앱 내 DM으로 제안을 보내도 회신이 없음</KakaoBottomContent>
                            </KakaoBottom>
                        </KakaoBox>
                        <KakaoBox>
                            <KakaoContentWrap>
                                <KakaoIcom src={require("../images/Main_Mobile2/Group 18550.png")} />
                                <KakaoTitle>인플루언서 DM</KakaoTitle>
                                <KakaoContent>
                                    포스팅 가이드라인 전달, 계약서 전달도 크리에이터리가 대신 해줘요! <br />
                                    <KakaoContentBlue>크리에이터리는 인플루언서의 개인 연락처를 기반으로 매일 개인 SNS 분석 리포트를 카카오톡으로 보내고 있습니다. 이때 협찬 제안도 함께 보내고 있어 응답율이 매우 높습니다.</KakaoContentBlue>
                                </KakaoContent>
                                <Line></Line>
                            </KakaoContentWrap>
                            
                            <KakaoImgWrap>
                                <KakaoImg src={require("../images/Main_Mobile2/KakaoImg3.png")} />
                                <KakaoImg src={require("../images/Main_Mobile2/KakaoImg4.png")} />
                            </KakaoImgWrap>

                            <KakaoBottom background="#4480f7">
                                <KakaoBottomPercent>응답률 72% 이상</KakaoBottomPercent>
                                <KakaoBottomContent>크리에이터리는 개인 카카오톡으로 광고 제안을 보내 참여도가 높음</KakaoBottomContent>
                            </KakaoBottom>
                        </KakaoBox>
                    </KakaoWrap>
                </Container>
            </Kakao>

            <Efficiency>
                <Container>
                    <Additional>크리에이터리 애즈는 빠르고 효율적입니다</Additional>
                    <SmallAdditional>최대 1개월 내 인플루언서 모집부터 포스팅 업로드까지 완료될 수 있습니다</SmallAdditional>
                    <EfficiencyWrap>
                        <EfficiencyItem data-aos="zoom-in" data-aos-delay="100" data-aos-anchor-placement="top-center">
                            <EfficiencyImg src={require("../images/About_PC/Group 19794.png")} />
                            <EfficiencyLabel>카카오톡 제안 및 모집</EfficiencyLabel>
                            <EfficiencyPeriod>5일 내</EfficiencyPeriod>
                        </EfficiencyItem>
						<EfficiencyItem data-aos="zoom-in" data-aos-delay="200" data-aos-anchor-placement="top-center">
                            <EfficiencyImg src={require("../images/About_PC/Group 19795.png")} />
                            <EfficiencyLabel>협찬 상품 배송</EfficiencyLabel>
                            <EfficiencyPeriod>1~3일 내</EfficiencyPeriod>
                        </EfficiencyItem>
						<EfficiencyItem data-aos="zoom-in" data-aos-delay="300" data-aos-anchor-placement="top-center">
                            <EfficiencyImg src={require("../images/About_PC/Group 19796.png")} />
                            <EfficiencyLabel>포스팅</EfficiencyLabel>
                            <EfficiencyPeriod>2주 이내</EfficiencyPeriod>
                        </EfficiencyItem>
                    </EfficiencyWrap>
                </Container>
            </Efficiency>

			<WhoAreWe>
				<Container>
					<WhoAreWeTitle>Who Are We?</WhoAreWeTitle>
					<WhoAreWeWrap>
						<WhoAreWeimg data-aos="zoom-in" src={require("../images/About_PC/L36A3554 1.png")} />

						<WhoAreWeBox data-aos="zoom-in">
							<BoldWhoAreWeContent>
								크리에이터리 팀은 크리에이터를 위한 <br />
								소프트웨어를 개발하는 IT기업입니다
							</BoldWhoAreWeContent>

							<WhoAreWeButtonWrap>
								<WhoAreWeButtonBox>
									<WhoAreWeButtonLogo max_width={"134px"} src={require("../images/About_PC/Frame 18898.png")} />
									<WhoAreWeButtonText>SNS 성장을 돕는 분석 리포트 서비스</WhoAreWeButtonText>
									<WhoAreWeButton href='https://meercat.io/'>자세히 보기</WhoAreWeButton>
								</WhoAreWeButtonBox>
								<WhoAreWeButtonBox>
									<WhoAreWeButtonLogo max_width={"152px"} src={require("../images/About_PC/Group 18551.png")} />
									<WhoAreWeButtonText>크리에이터를 위한 똑똑한 비즈니스 매니저</WhoAreWeButtonText>
									<WhoAreWeButton href='https://creator.ly/'>자세히 보기</WhoAreWeButton>
								</WhoAreWeButtonBox>
								<WhoAreWeButtonBox>
									<WhoAreWeButtonLogo max_width={"202px"} src={require("../images/About_PC/Group 19492.png")} />
									<WhoAreWeButtonText>광고주를 위한 인플루언서 마케팅</WhoAreWeButtonText>
									<WhoAreWeButton onClick={()=>{navigate("/")}}>자세히 보기</WhoAreWeButton>
								</WhoAreWeButtonBox>

								<MbWhoAreWeButtonBox>
									<MbWhoAreWeButton>
										<WhoAreWeButtonLogo max_width={"122px"} src={require("../images/About_PC/Frame 18898.png")} />
									</MbWhoAreWeButton>
									<MbWhoAreWeButton>
										<WhoAreWeButtonLogo max_width={"140px"} src={require("../images/About_PC/Group 18551.png")} />
									</MbWhoAreWeButton>
									<MbWhoAreWeButton>
										<WhoAreWeButtonLogo max_width={"190px"} src={require("../images/About_PC/Group 19492.png")} />
									</MbWhoAreWeButton>
								</MbWhoAreWeButtonBox>
							</WhoAreWeButtonWrap>

							<WhoAreWeContent>
								인스타그램 인플루언서의 SNS 성장을 돕는 분석 리포트 서비스 ‘미어캣IO’, MCN 업무를 크리에이터가 혼자서 할 수 있도 <br />
								록 도와주는 앱 ‘크리에이터리’, 인플루언서 마케팅을 맡기고 싶은 광고주를 위한 ‘크리에이터리 애즈'를 운영하고 있습니다
							</WhoAreWeContent>
						</WhoAreWeBox>

						<WhoAreWeBox data-aos="zoom-in">
							<BoldWhoAreWeContent>
								우리는 고객사의 목표 달성과 고객 만족을 <br />
								가장 중요한 원칙으로 생각합니다
							</BoldWhoAreWeContent>
							<WhoAreWeContent>
								크리에이터리 팀은 효율성을 고민하는 팀입니다. ‘어떻게하면 더 효율적으로 업무를 진행할 수 있을까?’ ‘어떻게하면 더 성장
								할 수 있을까?’ 이 고민은 비단 우리 팀만을 위한 것이 아닙니다. 우리는 고객사의 목표를 해결해주면서 효율성을 고민하고
								성장하고 있습니다. 우리가 이렇게 빠르게 성장하고 인정받을 수 있었던 것은 고객을 최우선적으로 생각하고 함께 성장한다 
								고 믿기 때문입니다.
							</WhoAreWeContent>
						</WhoAreWeBox>
					</WhoAreWeWrap>
				</Container>
			</WhoAreWe>

			<Premier>
				<Container>
					<Additional>크리에이터리 수상 이력</Additional>
					<SmallAdditional>크리에이터리는 기술력과 성장성을 인정받은 크리에이터/인플루언서 전문가 집단입니다.</SmallAdditional>
					<WinningItemWrap id='WinningItemWrap'>
                        <WinningItem data-aos="zoom-in" data-aos-delay="100" data-aos-anchor="#WinningItemWrap" src={require("../images/About_PC/Group 19800.png")} />
                        <WinningItem data-aos="zoom-in" data-aos-delay="300" data-aos-anchor="#WinningItemWrap" src={require("../images/About_PC/Group 19801.png")} />
                        <WinningItem data-aos="zoom-in" data-aos-delay="500" data-aos-anchor="#WinningItemWrap" src={require("../images/About_PC/Group 19802.png")} />
						<WinningItem data-aos="zoom-in" data-aos-delay="700" data-aos-anchor="#WinningItemWrap" src={require("../images/About_PC/Group 19799.png")} />
                        <WinningItem data-aos="zoom-in" data-aos-delay="900" data-aos-anchor="#WinningItemWrap" src={require("../images/About_PC/Group 19798.png")} />
                        <WinningItem data-aos="zoom-in" data-aos-delay="1100" data-aos-anchor="#WinningItemWrap" src={require("../images/About_PC/Group 19797.png")} />
                    </WinningItemWrap>
                    <MbWinningItemWrap id='MbWinningItemWrap'>
                        <WinningItem data-aos="zoom-in" data-aos-delay="100" data-aos-anchor="#MbWinningItemWrap" src={require("../images/Main_Mobile1/Group 19564.png")} />
                        <WinningItem data-aos="zoom-in" data-aos-delay="300" data-aos-anchor="#MbWinningItemWrap" src={require("../images/Main_Mobile1/Group 19565.png")} />
                        <WinningItem data-aos="zoom-in" data-aos-delay="500" data-aos-anchor="#MbWinningItemWrap" src={require("../images/Main_Mobile1/Group 19566.png")} />
						<WinningItem data-aos="zoom-in" data-aos-delay="700" data-aos-anchor="#MbWinningItemWrap" src={require("../images/Main_Mobile2/Group 19838.png")} />
                        <WinningItem data-aos="zoom-in" data-aos-delay="900" data-aos-anchor="#MbWinningItemWrap" src={require("../images/Main_Mobile2/Group 19839.png")} />
                        <WinningItem data-aos="zoom-in" data-aos-delay="1100" data-aos-anchor="#MbWinningItemWrap" src={require("../images/Main_Mobile2/Group 19840.png")} />
                    </MbWinningItemWrap>
				</Container>
			</Premier>

            <Footer/>
        </>
    );
}

// 컨테이너
const Container = styled.div` width: 100%; max-width: 1050px; margin: 0 auto;
    @media screen and (max-width: 1090px) {
        padding: 0 16px;
    }
`;
// 타이틀
const Title = styled.p`
    font-size: 24px;
    font-weight: bold;
    color: #a3b0bb;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-bottom: 20px;
    @media screen and (max-width: 880px) {
        font-size: 18px;
        margin-bottom: 15px;
    }
`
// 타이틀 아이콘
const Icon = styled.img`
    display: inline-block;
    max-width: 28px;
    @media screen and (max-width: 880px) {
        max-width: 20px;
    }
`
// 타이틀 아래 글씨
const Additional = styled.p`
    font-size: 46px;
    line-height: 1.5;
    font-weight: 800;
    color: #222222;
    text-align: center;
    margin-bottom: ${(props) => props.margin_bottom || "0px"};
    @media screen and (max-width: 880px) {
        font-size: 24px;
    }
`;
const SmallAdditional = styled.p`
    font-size: 20px;
    opacity: 0.6;
    color: #000;
    font-size: 20px;
    margin-top: 15px;
    text-align: center;
    @media screen and (max-width: 880px) {
        font-size: 16px;
    }
`


// section1=========================================================================
const Banner = styled.div` 
    position: relative;
    height: auto; 
    background-color: #fff;
    padding: 150px 0 118px;
    @media screen and (max-width: 800px) {
        padding: 80px 0;
    }
`;
const BannerLetterWrap = styled.div`
    position: relative;
    text-align: center;
    z-index: 1;
`
const BannerBigLetter = styled.p`
    font-size: 70px;
    font-weight: 800;
    color: #111;
    margin-bottom: 10px;
    @media screen and (max-width: 800px) {
        font-size: 28px;
    }
`
const BannerBigLetterBlue = styled(BannerBigLetter)`
    color: #4480f7;
    margin-bottom: 60px;
`;
const BannerBackground = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
`;
const TalkWrap = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 38px;
    @media screen and (max-width: 800px) {
        gap: 30px;
        align-items: center
    }
`
const TalkItem = styled.li`
    display: flex;
    position: relative;
    justify-content: center;
    @media screen and (max-width: 800px) {
        justify-content: center;
        align-items: center;
        max-width: 400px;
    }
`
const People1 = styled.img`
    max-width: 14.5%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 22px;
    @media screen and (max-width: 800px) {
        position: static;
        max-width: 63px;
        transform: unset;
    }
`
const SpeechBubble1 = styled.img`
    width: 55%;
    margin-right: 6%;
    @media screen and (max-width: 800px) {
        display: none;
    }
`
const People2 = styled.img`
    max-width: 11.5%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 22px;
    @media screen and (max-width: 800px) {
        position: static;
        max-width: 53px;
        transform: unset;
    }
`
const SpeechBubble2 = styled.img`
    width: 55%;
    margin-left: 6%;
    @media screen and (max-width: 800px) {
        display: none;
    }
`

const MbSpeechBubble1 = styled.img`
    display: none;
    width: calc(100% - 63px);
    @media screen and (max-width: 800px) {
        display: block;
        /* max-width: 252px; */
    }
`
const MbSpeechBubble2 = styled.img`
    display: none;
    width: calc(100% - 63px);
    @media screen and (max-width: 800px) {
        display: block;
        /* max-width: 252px; */
    }
`

const rotate = keyframes` /* 2. css코드를 씀. */
    0%{
        top: 100%;
    }
    100%{
        top: -100px;
    }
`;
const AnimationItem = styled.div`
    position: absolute;
    width: 27px;
    height: 27px;
    border-radius: 100%;
    background-color: #4480f7;
    top: 100%;
    left: ${(props) => props.left || "0px"};
    opacity: ${(props) => props.opacity || "1"};
    animation: ${rotate};
    animation-duration: 6s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-play-state: running;
    animation-delay: ${(props) => props.delay || "0s"};
    @media screen and (max-width: 800px) {
        display: ${(props) => props.mbdisplay || "none"};
        left: ${(props) => props.mbLeft || "0px"};
        width: 14px;
        height: 14px;
    }
`
const AnimationItemIcon = styled(AnimationItem)`
    width: ${(props) => props.width || "61px"};
    height: auto;
    background-color: unset;
    @media screen and (max-width: 800px) {
        width: 36px;
    }
`;
const AnimationItemIconImg = styled.img`
    width: 100%;
`

// section2=========================================================================
const Difference = styled.div`
    padding: 150px 0;
    @media screen and (max-width: 800px) {
        padding: 0 0 80px;
    }
`
const DifferenceWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    @media screen and (max-width: 800px) {
        gap: 20px;
    }
`
const DifferencTop = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 138px;
    border-radius: 20px;
    background-color: #081525;
    gap: 15px;
    @media screen and (max-width: 800px) {
        height: 60px;
        gap: 10px;
    }
`
const DifferencTopLogo = styled.img`
    max-width: 209px;
    @media screen and (max-width: 800px) {
        max-width: 122px;
    }
`
const DifferencTopLetter = styled.p`
    font-size: 36px;
    font-weight: bold;
    color: #fff;
    @media screen and (max-width: 800px) {
        font-size: 18px;
    }
`
const DifferencBox = styled.div`
    padding: 50px;
    border-radius: 20px;
    background-color: #f8f9fb;
    width: 100%;
    @media screen and (max-width: 800px) {
        padding: 20px 15px;
    }
`
const BigDifferencLetter = styled.p`
    line-height: 1.5;
    font-size: 36px;
    font-weight: bold;
    color: #212227;
    @media screen and (max-width: 800px) {
        font-size: 20px;
        margin-bottom: 15px;
    }
`
const DifferencLetter = styled.p`
    font-size: 20px;
    opacity: 0.6;
    color: #000;
    line-height: 1.5;
    @media screen and (max-width: 800px) {
        font-size: 15px;
        line-height: 1.6;
    }
`
const DifferenceImg = styled.img`
    margin-top: 80px;
    @media screen and (max-width: 800px) {
        display: none;
    }
`
const MvDifferenceImg = styled.img`
    display: none;
    @media screen and (max-width: 800px) {
        display: block;
        max-width: 400px;
        margin: 30px auto 0;
    }
`

// section3=========================================================================
const Kakao = styled.div`
    padding: 150px 0;
    background-color: #f6f8fa;
    @media screen and (max-width: 800px) {
        padding: 80px 0;
    }
`
const KakaoWrap = styled.ul`
    display: flex;
    gap: 16px;
    padding-top: 60px;
    @media screen and (max-width: 800px) {
        padding-top: 30px;
        flex-direction: column;
        gap: 20px;
    }
`
const KakaoBox = styled.li`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 20px;
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;
    width: calc((100% - 16px) / 2);
    padding-bottom: 146px;
    @media screen and (max-width: 800px) {
        width: 100%;
        padding: 15px;
        padding-bottom: 122px;
    }
`

const KakaoContentWrap = styled.div`
    position: relative;
    min-height: 270px;
    @media screen and (max-width: 800px) {
        min-height: unset;
    }
`
const Line = styled.div`
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #f6f8fa;
    bottom: 30px;
`

const KakaoIcom = styled.img`
    max-width: 48px;
    margin-bottom: 20px;
    @media screen and (max-width: 800px) {
        margin-bottom: 15px;
    }
`
const KakaoTitle = styled.p`
    font-size: 25px;
    font-weight: bold;
    color: #111;
    margin-bottom: 10px;
    @media screen and (max-width: 800px) {
        font-size: 20px;
    }
`
const KakaoContent = styled.p`
    font-size: 16px;
    line-height: 1.6;
    color: #000;
    opacity: 0.6;
    margin-bottom: 30px;
    @media screen and (max-width: 800px) {
        font-size: 14px;
    }
`
const KakaoContentBlue = styled.span`
    color: #4480f7;
`

const KakaoImgWrap = styled.div`
    display: flex;
    gap: 16px;
    @media screen and (max-width: 800px) {
        gap: 8px;
    }
`
const KakaoImg = styled.img`
    width: calc((100% - 16px) / 2);
    @media screen and (max-width: 800px) {
        width: calc((100% - 8px) / 2);
    }
`

const KakaoBottom = styled.div`
    display: flex;
    height: 126px;
    padding: 0 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: ${(props) => props.background || "#b0b9c1"};
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    @media screen and (max-width: 800px) {
        height: 92px;
    }
`
const KakaoBottomPercent = styled.p`
    font-size: 25px;
    font-weight: bold;
    color: #fff;
    @media screen and (max-width: 800px) {
        font-size: 20px;
    }
`
const KakaoBottomContent = styled.p`
    font-size: 16px;
    line-height: 1.6;
    color: #fff;
    text-align: center;
    @media screen and (max-width: 800px) {
        font-size: 14px;
    }
`

// section4=========================================================================
const Efficiency = styled.div`
    padding: 150px 0;
	@media screen and (max-width: 800px) {
        padding: 80px 0;
    }
`
const EfficiencyWrap = styled.ul`
	padding-top: 60px;
    display: flex;
	justify-content: space-evenly;
    gap: 16px;
	@media screen and (max-width: 530px) {
        flex-direction: column;
		gap: 60px;
    }
`
const EfficiencyItem = styled.li`
    display: flex;
    flex-direction: column;
    align-items: center;
	
`
const EfficiencyImg = styled.img`
    max-width: 200px;
    margin-bottom: 30px;
	@media screen and (max-width: 688px) {
        max-width: 120px;
		margin-bottom: 20px;
    }
`
const EfficiencyLabel = styled.p`
    font-size: 26px;
    font-weight: bold;
    color: #111;
    margin-bottom: 15px;
	@media screen and (max-width: 688px) {
        font-size: 20px;
    }
`
const EfficiencyPeriod = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 144px;
    height: 40px;
    border-radius: 20px;
    background-color: #4480f71A;
    font-size: 20px;
    color: #4480f7;
	@media screen and (max-width: 688px) {
        font-size: 14px;
		width: 81px;
		height: 33px;
    }
`

// section5=========================================================================
const WhoAreWe = styled.div`
	padding: 150px 0;
	background-color: #fafafa;
	@media screen and (max-width: 800px) {
        padding: 80px 0;
    }
`
const WhoAreWeTitle = styled.p`
	font-size: 46px;
	font-weight: 800;
	color: #222;
	margin-bottom: 30px;
	@media screen and (max-width: 800px) {
        font-size: 24px;
		margin-bottom: 20px;
    }
`
const WhoAreWeWrap = styled.div`
	display: flex;
	gap: 20px;
	flex-direction: column;
`
const WhoAreWeimg = styled.img``
const WhoAreWeBox = styled.div`
	padding: 50px;
	border-radius: 20px;
	background-color: #fff;
	@media screen and (max-width: 800px) {
        padding: 24px 15px 20px;
    }
`
const BoldWhoAreWeContent = styled.p`
	font-weight: bold;
	font-size: 36px;
	line-height: 1.5;
	color: #212227;
	@media screen and (max-width: 800px) {
		font-size: 20px;
    }
`
const WhoAreWeContent = styled.p`
	font-size: 20px;
	color: #000;
	opacity: 0.6;
	line-height: 1.6;
	margin-top: 30px;
	@media screen and (max-width: 800px) {
		font-size: 14px;
    }
`

const WhoAreWeButtonWrap = styled.ul`
	display: flex;
	gap: 25px;
	padding-top: 35px;
	@media screen and (max-width: 800px) {
		padding-top: 20px;
    }
`
const WhoAreWeButtonBox = styled.li`
	width: calc((100% - 50px) / 3);
	padding: 20px 20px 73px;
	position: relative;
	border-radius: 10px;
	border: solid 1px #eee;
	overflow: hidden;
	@media screen and (max-width: 800px) {
		display: none;
    }
`
const MbWhoAreWeButtonBox = styled.li`
	display: none;
	@media screen and (max-width: 800px) {
		display: flex;
		flex-direction: column;
		gap: 10px;
		width: 100%;
    }
`
const MbWhoAreWeButton = styled.a`
	display: flex;
	width: 100%;
	height: 68px;
	border-radius: 10px;
	border: solid 1px #eee;
	justify-content: center;
	align-items: center;
`

const WhoAreWeButtonLogo = styled.img`
	max-width: ${(props) => props.max_width || "0"};
	margin-bottom: 20px;
	@media screen and (max-width: 800px) {
		margin-bottom: unset;
    }
`
const WhoAreWeButtonText = styled.p`
	color: #555;
`
const WhoAreWeButton = styled.a`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 43px;
	background-color: #4480f7;
	display: flex;
	padding: 0 21px;
	align-items: center;
	color: #fff;
	font-size: 14px;
	transition: all 0.3s;
    cursor: pointer;
	&:hover{
        background-color : #296ff9;
    }
`

// section6=========================================================================
const Premier = styled.div`
	padding: 150px 0;

`
const WinningItemWrap = styled.div`
    display: flex;
    gap: 24px;
    padding-top: 60px;
	flex-wrap: wrap;
    @media screen and (max-width: 640px) {
        display: none;
    }
`
const WinningItem = styled.img`
    width: calc((100% - 48px) / 3);
    @media screen and (max-width: 640px) {
        width: 100%;
        max-width: 300px;
    }
`
const MbWinningItemWrap = styled.div`
    display: none;
    align-items: center;
    flex-direction: column;
    padding-top: 30px;
    gap: 15px;
    @media screen and (max-width: 640px) {
        display: flex;
    }
`




export default About;

