import { Routes, Route, Link, useNavigate, Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';


import styled, {keyframes} from 'styled-components';

import Header from '../components/Header'
import Footer from '../components/Footer'

import Loading from '../pages/Loading';

// 페이드인
import AOS from "aos";
import "aos/dist/aos.css";


function Business() {
    let navigate = useNavigate();
    // useEffect(() => {
    //     AOS.init();
    // })

    return (
        <>
            {/* header */}
            <Header mainHeader={false}></Header>

            <Banner>
                <BannerLetterWrap>
                    <Container data-aos="zoom-in">
                        <BannerBigLetter>성과내는 가장 효율적인</BannerBigLetter>
                        <BannerBigLetterBlue>인플루언서 마케팅</BannerBigLetterBlue>
                        <BannerBigLetterSmall>
                            크리에이터리 애즈의 솔루션은 인지도를 높이고 <MbBreak /> 매출을 냅니다 <Break />
                            디지털 시장에서의 고객 여정, 인플<MbBreak />루언서 시장 그리고 <Break />
                            고객사를 종합 분석하여 가장<MbBreak /> 효과적인 솔루션을 제공합니다.
                        </BannerBigLetterSmall>
                    </Container>
                </BannerLetterWrap>

                <BannerBackground>

                    <AnimationItemIcon left={"24%"} delay={"-5s"} mbdisplay mbLeft={"20%"}>
                        <AnimationItemIconImg src={require("../images/About_PC/Group 19790.png")}></AnimationItemIconImg>
                    </AnimationItemIcon>

                    <AnimationItem left={"38%"} delay={"-5.5s"} opacity={"0.6"} mbdisplay mbLeft={"40%"}></AnimationItem>

                    <AnimationItem left={"70%"} delay={"-7s"} mbdisplay mbLeft={"63%"}></AnimationItem>

                    <AnimationItemIcon left={"77%"} delay={"-6s"} width={"83px"} mbdisplay mbLeft={"70%"}>
                        <AnimationItemIconImg src={require("../images/About_PC/Group 19789.png")}></AnimationItemIconImg>
                    </AnimationItemIcon>

                </BannerBackground>
            </Banner>

            <Influencer>
                <Container data-aos="zoom-in">
                    <Title>
                        <Icon src={require("../images/Business_PC/Badge check.png")}/>
                        Influencer Marketing
                    </Title>
                    <Additional>인플루언서 마케팅은 왜 해야할까요?</Additional>
                    <SmallAdditional>
                        인플루언서란 SNS 상에서 트렌드를 이끌어가는 사람을 말합니다. 인플루언서 마케팅은 이들의 영향력을 통하여<Break />
                        상대적으로 짧은 시간 안에 큰 규모의 잠재고객을 확보할 수 있다는 장점이 있습니다
                    </SmallAdditional>
                    <InfluencerWrap>
                        <InfluencerBox>
                            <InfluencerImg src={require("../images/Business_PC/Mask group-1.png")} />
                            <InfluencerContentWrap>
                                <InfluencerLabel>인지도 확보</InfluencerLabel>
                                <InfluencerContent>
                                    ‘인플루언서 콘텐츠를 통해 상품/서비스를 인지한 적이 있다’ (64%) 
                                    인플루언서 마케팅은 가장 효율적으로 브랜드 인지도를 올릴 수 있는 마케팅이비다. 인플루언서를 매개로 친숙하고 자연스러운 방식으로 잠재고객과 소통할 수 있기 때문에 바이럴 마케팅 효과를 불러옵니다.
                                </InfluencerContent>
                            </InfluencerContentWrap>
                        </InfluencerBox>

                        <InfluencerBox>
                            <InfluencerImg src={require("../images/Business_PC/Mask group-2.png")} />
                            <InfluencerContentWrap>
                                <InfluencerLabel>매출 증대</InfluencerLabel>
                                <InfluencerContent>
                                    ‘인플루언서 콘텐츠에서 정보 획득 후 실제 제품/서비스 및 구매로 이어진 적이 있다’ (76%)  인플루언서의 라이프스타일을 추종하는 팬덤형 소비자가 늘어나고 있습니다. 팔로워들은 인플루언서의 활동을 지켜보며 신뢰감과 내적 친밀감을 쌓아가고 그에 대한 믿음으로 제품을 구매합니다.
                                </InfluencerContent>
                            </InfluencerContentWrap>
                        </InfluencerBox>
                        
                        <InfluencerBox>
                            <InfluencerImg src={require("../images/Business_PC/Mask group-3.png")} />
                            <InfluencerContentWrap>
                                <InfluencerLabel>바이럴 마케팅</InfluencerLabel>
                                <InfluencerContent>
                                    ‘인플루언서의 콘텐츠를 지인에게 공유하거나 추천한 적이 있다’ (86%)
                                    다수의 인플루언서 시딩 작업을 진행하여 게시물을 일종의 챌린지처럼 기획할 수도 있습니다. 소비자들은 인플루언서처럼 해시태그를 올려 리뷰 콘텐츠를 따라 작성하기도 합니다. 이러한 현상이 자동으로 확산되어 자연스러운 바이럴 마케팅이 될 수 있습니다.
                                </InfluencerContent>
                            </InfluencerContentWrap>
                        </InfluencerBox>

                        <InfluencerBox>
                            <InfluencerImg src={require("../images/Business_PC/Mask group-4.png")} />
                            <InfluencerContentWrap>
                                <InfluencerLabel>2차 활용 소스 확보</InfluencerLabel>
                                <InfluencerContent>
                                    나노, 마이크로 인플루언서의 리뷰 콘텐츠를 다양한 곳에 활용할 수 있습니다. 제품 사진만으로 여러 소재를 만드는 것은 구매전환에 한계가 있습니다. 인플루언서의 콘텐츠를 2차 활용하여 소재를 제작하여 광고를 집행할 수 있습니다. 또한 랜딩페이지 및 상세페이지에 리뷰 이미지로 활용하면 제품에 대한 신뢰도를 높여 전환을 높일 수 있습니다.
                                </InfluencerContent>
                            </InfluencerContentWrap>
                        </InfluencerBox>
                    </InfluencerWrap>
                </Container>
            </Influencer>

            <CDJ>
                <Container data-aos="zoom-in">
                    <Title>
                        <Icon src={require("../images/Business_PC/Map.png")}/>
                        Influencer Marketing
                    </Title>
                    <Additional>크리에이터리 애즈는 CDJ를 기반으로 <Break /> 다양한 솔루션을 제공합니다</Additional>
                    <CDJCircleWrap>
                        <CDJCircleItem>
                            <CDJCircleImg src={require("../images/Business_PC/Group 19835.png")} />
                            <CDJCirclePercent>64%</CDJCirclePercent>
                            <CDJCircleExplanation>인플루언서 콘텐츠를 통해 <Break />상품 / 서비스를 인지</CDJCircleExplanation>
                        </CDJCircleItem>

                        <CDJCircleItem>
                            <CDJCircleImg src={require("../images/Business_PC/Group 19834.png")} />
                            <CDJCirclePercent color={"#4461f7"}>84%</CDJCirclePercent>
                            <CDJCircleExplanation>인플루언서 콘텐츠를 통한 <Break />상품/서비스 추가정보 탐색</CDJCircleExplanation>
                        </CDJCircleItem>

                        <CDJCircleItem>
                            <CDJCircleImg src={require("../images/Business_PC/Group 19833.png")} />
                            <CDJCirclePercent>76%</CDJCirclePercent>
                            <CDJCircleExplanation>인플루언서 콘텐츠에서 정보 <Break />획득 후 실제 제품/서비스 구매</CDJCircleExplanation>
                        </CDJCircleItem>

                        <CDJCircleItem>
                            <CDJCircleImg src={require("../images/Business_PC/Group 19832.png")} />
                        </CDJCircleItem>
                    </CDJCircleWrap>
                    <CDJItemWrap>
                        <CDJItem>
                            <CDJItemTitle>Awareness</CDJItemTitle>
                            <CDJItemListWrap>
                                <CDJItemList>
                                    <CDJItemLabel>인스타그램 인플루언서 유튜브 크리에이터를 통한 브랜드 인지</CDJItemLabel>
                                    <CDJcontentWrap>
                                        <CDJconten>인플루언서 콘텐츠를 통해 상품 / 서비스를 인지한 적이 있다. <CDJcontenBold>64%</CDJcontenBold> </CDJconten>
                                    </CDJcontentWrap>
                                </CDJItemList>
                                <CDJItemList>
                                    <CDJItemLabel font_size={"20px"}>Creatorly Strategy</CDJItemLabel>
                                    <CDJcontentWrap>
                                        <CDJconten>인플루언서 시딩 캠페인</CDJconten>
                                    </CDJcontentWrap>
                                </CDJItemList>
                            </CDJItemListWrap>
                        </CDJItem>
                        <CDJItem>
                            <CDJItemTitle>Consideration</CDJItemTitle>
                            <CDJItemListWrap>
                                <CDJItemList>
                                    <CDJItemLabel>SNS를 통한 정보 검색 <Break /> SNS에서 브랜드 인지 후 포탈에서 정보 검색</CDJItemLabel>
                                    <CDJcontentWrap>
                                        <CDJconten>인플루언서 콘텐츠를 통해 인지하게 된 상품 / 서비스에 대한 추가 정보를 찾아본 적이 있다. <CDJcontenBold>84%</CDJcontenBold></CDJconten>
                                        <CDJconten>정보 검색 시 이용하는 서비스 <Break /> 유튜브 <CDJcontenBold>54.7%</CDJcontenBold> 인스타그램 <CDJcontenBold>21.2%</CDJcontenBold></CDJconten>
                                    </CDJcontentWrap>
                                </CDJItemList>
                                <CDJItemList>
                                    <CDJItemLabel font_size={"20px"}>Creatorly Strategy</CDJItemLabel>
                                    <CDJcontentWrap>
                                        <CDJconten>포스팅 가이드라인, 필수 해시태그 입력</CDJconten>
                                    </CDJcontentWrap>
                                </CDJItemList>
                            </CDJItemListWrap>
                        </CDJItem>
                        <CDJItem>
                            <CDJItemTitle>Purchase</CDJItemTitle>
                            <CDJItemListWrap>
                                <CDJItemList>
                                    <CDJItemLabel>제품 구매 단계</CDJItemLabel>
                                    <CDJcontentWrap>
                                        <CDJconten>인플루언서 콘텐츠에서 정보 획득 후 실제 제품 / 서비스 구매 <CDJcontenBold>76%</CDJcontenBold></CDJconten>
                                    </CDJcontentWrap>
                                </CDJItemList>
                                <CDJItemList>
                                    <CDJItemLabel font_size={"20px"}>Creatorly Strategy</CDJItemLabel>
                                    <CDJcontentWrap>
                                        <CDJconten>인스타그램 판매 극대화 전략</CDJconten>
                                        <CDJconten2>- BCA(Branded Contents Ads)</CDJconten2>
                                    </CDJcontentWrap>
                                </CDJItemList>
                            </CDJItemListWrap>
                        </CDJItem>
                        <CDJItem>
                            <CDJItemTitle>Loyalty</CDJItemTitle>
                            <CDJItemListWrap>
                                <CDJItemList>
                                    <CDJItemLabel>고객 충성 단계</CDJItemLabel>
                                    <CDJcontentWrap>
                                        <CDJconten>제품 / 서비스 경험 후 만족에 의해 충성고객이 됨</CDJconten>
                                        <CDJconten>인플루언서의 라이프스타일을 추종하는 팬들이 잠재적 충성고객이 됨</CDJconten>
                                    </CDJcontentWrap>
                                </CDJItemList>
                                <CDJItemList>
                                    <CDJItemLabel font_size={"20px"}>Creatorly Strategy</CDJItemLabel>
                                    <CDJcontentWrap>
                                        <CDJconten>콘텐츠 2차 활용 (랜딩페이지, 리뷰페이지 등)</CDJconten>
                                    </CDJcontentWrap>
                                </CDJItemList>
                            </CDJItemListWrap>
                        </CDJItem>
                    </CDJItemWrap>
                </Container>
            </CDJ>

            <Solutions>
                <Container data-aos="zoom-in">
                    <SolutionsWrap>
                        <SolutionsTextWrap>
                            <SolutionsText>
                                고객사를 분석하여 <br />
                                가장 적합한 솔루션으로 <br />
                                안내해드립니다
                            </SolutionsText>
                            <SolutionsButton onClick={()=>{navigate("/priceGuide")}}>솔루션 자세히 보기</SolutionsButton>
                        </SolutionsTextWrap>
                        <SolutionsImg src={require("../images/Business_PC/bulb.jpg")} />
                    </SolutionsWrap>
                </Container>
            </Solutions>

            <SpaceBanner>
                <SpaceBannerBg src={require("../images/Business_PC/Group 19836.png")} />
                <SpaceBannerText>
                    Building brand awareness,<MbBreak/> engagement<Break/>
                    and reach<MbBreak/> with Creatorly influeners
                </SpaceBannerText>
                <SmallSpaceBannerText>
                    크리에이터리 인플루언서와 함께 <MbBreak/> 진짜 성과내는 마케팅을 경험해보세요
                </SmallSpaceBannerText>
            </SpaceBanner>

            <BrandPartners>
                <Container data-aos="zoom-in" >
                    <BrandPartnersTitle>Brand Partners</BrandPartnersTitle>
                    <BrandPartnersImg src={require("../images/Business_PC/Group 19830.png")} />
                    <MbBrandPartnersImg src={require("../images/Main_Mobile3/Group 19749.png")} />
                </Container>
            </BrandPartners>

            

            <Footer/>
        </>
    );
}

// 컨테이너
const Container = styled.div` width: 100%; max-width: 1050px; margin: 0 auto;
    @media screen and (max-width: 1090px) {
        padding: 0 16px;
    }
`;
// 타이틀
const Title = styled.p`
    font-size: 24px;
    font-weight: bold;
    color: #a3b0bb;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-bottom: 20px;
    @media screen and (max-width: 880px) {
        font-size: 18px;
        margin-bottom: 15px;
    }
`
// 타이틀 아이콘
const Icon = styled.img`
    display: inline-block;
    max-width: 28px;
    @media screen and (max-width: 880px) {
        max-width: 20px;
    }
`
// 타이틀 아래 글씨
const Additional = styled.p`
    font-size: 46px;
    line-height: 1.5;
    font-weight: 800;
    color: #222222;
    text-align: center;
    margin-bottom: ${(props) => props.margin_bottom || "0px"};
    @media screen and (max-width: 880px) {
        font-size: 24px;
    }
`;
const SmallAdditional = styled.p`
    font-size: 20px;
    opacity: 0.6;
    color: #000;
    font-size: 20px;
    margin-top: 15px;
    text-align: center;
    line-height: 1.5;
    @media screen and (max-width: 880px) {
        font-size: 16px;
    }
`
const Break = styled.br`
    @media screen and (max-width: 800px) {
        display: none;
    }
` 
const MbBreak = styled.br`
    display: none;
    @media screen and (max-width: 800px) {
        display: unset;
    }
`

// section1=========================================================================
const Banner = styled.div` 
    position: relative;
    height: auto; 
    background-color: #fff;
    padding: 150px 0;
    @media screen and (max-width: 800px) {
        padding: 80px 0;
    }
`;
const BannerLetterWrap = styled.div`
    position: relative;
    text-align: center;
    z-index: 1;
`
const BannerBigLetter = styled.p`
    font-size: 70px;
    font-weight: 800;
    color: #111;
    margin-bottom: 10px;
    @media screen and (max-width: 800px) {
        font-size: 28px;
    }
`
const BannerBigLetterBlue = styled(BannerBigLetter)`
    color: #4480f7;
    margin-bottom: 40px;
    @media screen and (max-width: 800px) {
        margin-bottom: 20px;
    }
`;
const BannerBigLetterSmall = styled.p`
    opacity: 0.6;
    font-size: 24px;
    color: #000;
    line-height: 1.5;
    @media screen and (max-width: 800px) {
        font-size: 15px;
    }
`;
const BannerBackground = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
`;

const rotate = keyframes` /* 2. css코드를 씀. */
    0%{
        top: 100%;
    }
    100%{
        top: -100px;
    }
`;
const AnimationItem = styled.div`
    position: absolute;
    width: 27px;
    height: 27px;
    border-radius: 100%;
    background-color: #4480f7;
    top: 100%;
    left: ${(props) => props.left || "0px"};
    opacity: ${(props) => props.opacity || "1"};
    animation: ${rotate};
    animation-duration: 6s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-play-state: running;
    animation-delay: ${(props) => props.delay || "0s"};
    @media screen and (max-width: 800px) {
        display: ${(props) => props.mbdisplay || "none"};
        left: ${(props) => props.mbLeft || "0px"};
        width: 14px;
        height: 14px;
    }
`
const AnimationItemIcon = styled(AnimationItem)`
    width: ${(props) => props.width || "61px"};
    height: auto;
    background-color: unset;
    @media screen and (max-width: 800px) {
        width: 36px;
    }
`;
const AnimationItemIconImg = styled.img`
    width: 100%;
`

// section2=========================================================================
const Influencer = styled.div`
    padding: 150px 0;
    background-color: #fafafa;
    @media screen and (max-width: 800px) {
        padding: 80px 0;
    }
`
const InfluencerWrap = styled.ul`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 60px;
    gap: 20px 16px;
    justify-content: center;
    @media screen and (max-width: 800px) {
        padding-top: 30px;
    }
`
const InfluencerBox = styled.li`
    width: calc((100% - 16px) / 2);
    border-radius: 20px;
    background-color: #fff;
    @media screen and (max-width: 600px) {
        width: 100%;
        max-width: 400px;
    }
`
const InfluencerImg = styled.img``
const InfluencerContentWrap = styled.div`
    padding: 20px 20px 30px;
    @media screen and (max-width: 800px) {
        padding: 20px 15px;
    }
`
const InfluencerLabel = styled.p`
    font-size: 25px;
    font-weight: bold;
    color: #111;
    margin-bottom: 10px;
    @media screen and (max-width: 800px) {
        font-size: 18px;
    }
`
const InfluencerContent = styled.p`
    opacity: 0.6;
    line-height: 1.6;
    color: #000;
    @media screen and (max-width: 800px) {
        font-size: 14px;
    }
`
// section3=========================================================================
const CDJ = styled.div`
    padding: 150px 0;
    background-color: #f6f8fa;
    @media screen and (max-width: 800px) {
        padding: 80px 0;
    }
`
const CDJCircleWrap = styled.ul`
    padding: 0 23px;
    display: flex;
    margin-top: 60px;
    flex-wrap: wrap;
    gap: 40px 0;
`
const CDJCircleItem = styled.li`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% / 4);
    gap: 14px;
    @media screen and (max-width: 800px) {
        width: calc(100% / 2);
        gap: 5px;
    }
`
const CDJCircleImg = styled.img`
    @media screen and (max-width: 800px) {
        width: 86%;
        max-width: 200px;
    }
`
const CDJCirclePercent = styled.p`
    font-size: 36px;
    font-weight: bold;
    color: ${props => props.color || "#4480f7" } ;
    @media screen and (max-width: 800px) {
        font-size: 26px;
    }
`
const CDJCircleExplanation = styled.p`
    text-align: center;
    color: #000;
    opacity: 0.6;
    padding: 0 8px;
    line-height: 1.5;
    @media screen and (max-width: 800px) {
        font-size: 14px;
    }
`
const CDJItemWrap = styled.ul`
    padding-top: 90px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media screen and (max-width: 950px) {
        padding-top: 60px;
    }
`
const CDJItem = styled.li`
    padding: 50px;
    border-radius: 20px;
    background-color: #fff;
    display: flex;
    @media screen and (max-width: 950px) {
        flex-direction: column;
        gap: 20px;
        padding: 20px 15px;
    }
`
const CDJItemTitle = styled.h2`
    width: 290px;
    font-size: 36px;
    font-weight: bold;
    color: #4480f7;
    @media screen and (max-width: 950px) {
        width: 100%;
        font-size: 22px;
    }
`
const CDJItemListWrap = styled.ul`
    display: flex;
    width: calc(100% - 290px);
    flex-direction: column;
    gap: 40px;
    @media screen and (max-width: 950px) {
        width: 100%;
        gap: 30px;
    }
`
const CDJItemList = styled.li``
const CDJItemLabel = styled.p`
    font-size: ${props => props.font_size || "26px" };
    font-weight: bold;
    color: #111;
    line-height: 1.5;
    margin-bottom: 10px;
    @media screen and (max-width: 950px) {
        font-size: 18px;
    }
`
const CDJcontentWrap = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 8px;
`
const CDJconten = styled.li`
    position: relative;
    padding-left: 12px;
    font-size: 20px;
    color: #555;
    line-height: 1.5;
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 13px;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background-color: #555;
    }
    @media screen and (max-width: 950px) {
        font-size: 14px;
        &::after {
            top: 9px;
        }
    }

`
const CDJconten2 = styled(CDJconten)`
    padding-left: unset;
    &::after {
        display: none;
    }
`
const CDJcontenBold = styled.span`
    font-weight: bold;
`
// section4=========================================================================
const Solutions = styled.div`
    padding-bottom: 262px;
    @media screen and (max-width: 780px) {
        padding: 80px 0;
    }
`
const SolutionsWrap = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    @media screen and (max-width: 780px) {
        flex-direction: column;
        justify-content: unset;
        align-items: center
    }
`
const SolutionsTextWrap = styled.div`
    padding-top: 203px;
    @media screen and (max-width: 780px) {
        padding-top: unset;
    }
`
const SolutionsText = styled.p`
    margin-bottom: 60px;
    font-size: 36px;
    font-weight: bold;
    color: #212227;
    line-height: 1.5;
    @media screen and (max-width: 780px) {
        text-align: center;
        font-size: 26px;
        line-height: 1.4;
        margin-bottom: 24px;
    }
`
const SolutionsButton = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 176px;
    height: 54px;
    border-radius: 5px;
    background-color: #4480f7;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    transition: all 0.3s;
    cursor: pointer;
    &:hover{
        background-color: #296ff9;
    }
    @media screen and (max-width: 780px) {
        margin: 0 auto;
        width: 148px;
        height: 49px;
        font-size: 14px;
    }
`
const SolutionsImg = styled.img`
    max-width: 597px;
    position: absolute;
    z-index: -1;
    top: 0;
    right: -148px;
    @media screen and (max-width: 780px) {
        position: static;
        max-width: 285px;
        z-index: 1;
    }
`

// section5=========================================================================
const SpaceBanner = styled.div`
    padding: 104px 20px;
    position: relative;
    @media screen and (max-width: 800px) {
        padding: 30px 0;
    }
`
const SpaceBannerBg = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
`
const SpaceBannerText = styled.p`
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    color: #fff;
    line-height: 1.3;
    margin-bottom: 24px;
    @media screen and (max-width: 800px) {
        font-size: 18px;
        line-height: 1.5;
        margin-bottom: 15px;
    }
`
const SmallSpaceBannerText = styled.p`
    text-align: center;
    opacity: 0.6;
    font-size: 24px;
    color: #fff;
    @media screen and (max-width: 800px) {
        font-size: 16px;
        line-height: 1.5;
    }
`

// section6=========================================================================
const BrandPartners = styled.div`
    padding: 150px 0 300px;
    @media screen and (max-width: 800px) {
        padding: 80px 0;
    }
`
const BrandPartnersTitle = styled.p`
    text-align: center;
    font-size: 46px;
    font-weight: 800;
    margin-bottom: 60px;
    @media screen and (max-width: 800px) {
        font-size: 24px;
        margin-bottom: 30px;
    }
`
const BrandPartnersImg = styled.img`
    @media screen and (max-width: 800px) {
        display: none;
    }
`
const MbBrandPartnersImg = styled.img`
    display: none;
    @media screen and (max-width: 800px) {
        display: block;
        max-width: 500px;
        margin: 0 auto;
    }
`









export default Business;

