import styled from "styled-components";
import Floating1 from "../images/FloatingButton1.png";
import Floating2 from "../images/FloatingButton2.png";
import Floating3 from "../images/FloatingButton3.png";
import { useOverlay } from "../module/overlay/useOverlay";
import { RequestModal } from "./RequestModal";

export function Floating() {
  const { open } = useOverlay();

  return (
    <Wrap>
      <Container>
        <FloatingButton1 onClick={() => window.open("https://creator.ly")} />
        <FloatingButton2
          onClick={() =>
            open(({ open, isOpen, exit }) => (
              <RequestModal
                title="컨설팅 신청"
                isOpen={isOpen}
                onClose={exit}
              />
            ))
          }
        />
        <FloatingButton3
          onClick={() =>
            window.open("https://docsend.com/view/7egb4jnazy4j548k")
          }
        />
      </Container>
    </Wrap>
  );
}

const Wrap = styled.div`
  pointer-events: none;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1001;
  width: 100%;
`;

const Container = styled.div`
  position: relative;

  width: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
  padding: 16px;
  margin-bottom: 120px;
`;

const FloatingButton1 = styled.button`
  pointer-events: all;
  height: 80px;
  width: 80px;
  z-index: 1001;
  background-image: url(${Floating1});
  background-size: contain;
  :active {
    opacity: 0.6;
  }

  @media screen and (max-width: 760px) {
    width: 56px;
    height: 56px;
  }
`;

const FloatingButton2 = styled.button`
  pointer-events: all;
  height: 80px;
  width: 80px;
  z-index: 1001;
  background-image: url(${Floating2});
  background-size: contain;

  :active {
    opacity: 0.6;
  }
  @media screen and (max-width: 760px) {
    width: 56px;
    height: 56px;
  }
`;

const FloatingButton3 = styled.button`
  pointer-events: all;
  height: 80px;
  width: 80px;
  z-index: 1001;
  background-image: url(${Floating3});
  background-size: contain;
  :active {
    opacity: 0.6;
  }
  @media screen and (max-width: 760px) {
    width: 56px;
    height: 56px;
  }
`;
