import styled from "styled-components";

export const Input = styled.input`
  font-size: 16px;
  font-weight: 500;
  height: 40px;

  border-bottom: 1px solid lightgray;

  width: ${({ full }) => (full ? "100%" : "fit-content")};

  :not(:disabled) {
    :focus {
      border-bottom-color: rgb(68, 128, 247);
    }

    :valid {
      border-bottom-color: rgb(68, 128, 247);
    }
  }

  :disabled {
    cursor: not-allowed;
    color: lightgray;
    border-bottom-color: lightgray;
  }
`;
