import { useEffect, useState } from "react";
import { Routes, Route, Link, useNavigate, Outlet } from "react-router-dom";

// 페이지
import Main from "./pages/Main";
import About from "./pages/About";
import Business from "./pages/Business";
import PriceGuide from "./pages/PriceGuide";

// 로딩중
import Loading from "./pages/Loading";

// 페이지 전환시 스크롤 최상단
import ScrollToTop from "./ScrollToTop";

// 페이드인
import AOS from "aos";
import "aos/dist/aos.css";
import "react-toastify/dist/ReactToastify.css";
import { OverlayProvider } from "./module/overlay/OverlayProvider";
import { ToastContainer } from "react-toastify";
import { FixedFooter } from "./components/FixedFooter";
import { Floating } from "./components/Floating";

function App() {
  const [ready, setReady] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setReady(false);
    }, 1000);
  });

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="App">
      <ScrollToTop></ScrollToTop>
      {/* 로딩창 */}
      {ready ? <Loading></Loading> : ""}
      {/* body */}
      <OverlayProvider>
        <Routes>
          {/* 메인 */}
          <Route path="/" element={<Main></Main>}></Route>

          {/* 서비스 소개 */}
          <Route path="/about" element={<About></About>}></Route>

          {/* 비즈니스 */}
          <Route path="/business" element={<Business></Business>}></Route>

          {/* 가격 안내 */}
          <Route path="/priceGuide" element={<PriceGuide></PriceGuide>}></Route>

          {/* 404 */}
          <Route path="*" element={<div>404</div>}></Route>
        </Routes>{" "}
        <ToastContainer position="bottom-center" draggable />
        <FixedFooter />
        <Floating />
      </OverlayProvider>
    </div>
  );
}

export default App;
