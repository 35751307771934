import styled from "styled-components";

export const Flex = {
  Row: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  Column: styled.div`
    display: flex;
    flex-direction: column;
  `,
};
