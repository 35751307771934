import CountUp from "react-countup";
import styled from "styled-components";
import { useOverlay } from "../module/overlay/useOverlay";
import { RequestModal } from "./RequestModal";

const num = Math.floor(Math.random() * 15 + 10);

export function FixedFooter() {
  const { open, close, exit } = useOverlay();

  return (
    <Wrap>
      <Container>
        <div>
          <H3>분야별 인플루언서 마케팅 전문가 상시 대기중</H3>
          <P>
            지금,{" "}
            <CountUp
              style={{ color: "white", fontWeight: 700 }}
              end={num}
              duration={3}
            />
            명의 광고주님이 크리에이터를 보고 있습니다
          </P>
        </div>
        <Button
          onClick={() => {
            open(({ isOpen, close, exit }) => {
              return (
                <RequestModal
                  title={"컨설팅 신청"}
                  isOpen={isOpen}
                  onClose={exit}
                />
              );
            });
          }}
        >
          🧑🏻‍💻 무료 컨설팅 받아보기
        </Button>
      </Container>
    </Wrap>
  );
}

const Wrap = styled.footer`
  z-index: 1000;
  position: fixed;

  bottom: 0;
  left: 0;

  width: 100vw;
  height: 120px;

  background: linear-gradient(to right, #253aca, #6273ec);

  display: flex;
  align-items: flex-end;

  @media screen and (max-width: 760px) {
    height: 80px;
  }
`;

const Container = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1050px;
  width: 100%;

  margin: 0 auto;
  padding: 24px 32px;

  height: max-content;

  // background: linear-gradient(to right, #253aca, #6273ec);
  // border-radius: 8px 8px 0px 0px;

  animation: 0.6s slide-up ease;

  @keyframes slide-up {
    from {
      transform: translateY(200px);
    }
    to {
      transform: none;
    }
  }

  @media screen and (max-width: 760px) {
    padding: 16px 24px;
  }
`;

const Button = styled.button`
  background: black;
  color: white;
  font-size: 20px;
  font-weight: 600;
  padding: 20px 24px;
  border-radius: 8px;

  @media screen and (max-width: 760px) {
    background: none;
    width: 100%;
    height: 100%;
  }
`;

const H3 = styled.h3`
  color: white;
  font-size: 24px;
  font-weight: 700;

  @media screen and (max-width: 760px) {
    display: none;
  }
`;

const P = styled.p`
  margin-top: 8px;
  color: #939ad9;
  font-size: 16px;
  @media screen and (max-width: 760px) {
    display: none;
  }
`;
